import React, { PureComponent } from "react";
import axios from "axios";
import { server, RECAPTCHA_KEY } from "../env";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Recaptcha from "react-google-invisible-recaptcha";
import { notify } from "../utils/helper";

class Forgot extends PureComponent {
  state = {
    loader: false,
    reCaptcha: null,
  };

  forgot = async (e) => {
    e.preventDefault();
    await this.recaptcha.execute();
    this.setState({ loader: true });

    const form = e.target;
    const data = new FormData(form);
    data.append("g-recaptcha-response", await this.recaptcha.getResponse());

    localStorage.setItem("email", e.target.email.value);

    await axios
      .post(server + "/v1/user/forget-password", data)
      .then((rsp) => {
        window.location.href = "/reset?email=" + e.target.email.value;
      })
      .catch((err) => {
        if (err.response) {
          notify("error", err.response?.data?.message);
        }
      });
    this.setState({ loader: false });
  };

  onResolved = async () => {
    this.setState({ reCaptcha: this.recaptcha.getResponse() });
  };

  render() {
    const loader = this.state.loader;

    return (
      <div className="main-wrap-login" style={{ backgroundColor: "#FAFAFA" }}>
        <section className="signup-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6">
                <div className="signup-form-wrap">
                  <form onSubmit={this.forgot}>
                    <div className="d-flex">
                      <h1 style={{ position: "relative" }}>
                        <i
                          style={{
                            position: "absolute",
                            left: "-40px",
                            top: "3px",
                          }}
                          className="fas fa-arrow-left"
                        ></i>{" "}
                        Forgot Password
                      </h1>
                    </div>
                    <div className="form-p">
                      <p style={{ fontFamily: "Satoshi-Medium" }}>
                        Enter your email to receive OTP to reset password
                      </p>
                    </div>
                    <div className="form-group">
                      <label for="">Email Address</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                    <div className="captcha-part"></div>
                    <div className="form-bttn">
                      <button type="submit" className="btn btn_submit">
                        Send OTP {loader && <Loader />}
                      </button>
                    </div>
                    <div className="form-group">
                      <p>
                        Not a Member Yet ?{" "}
                        <Link to="/signup" style={{ color: "#2FA4FF" }}>
                          Create Account
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Recaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={RECAPTCHA_KEY}
          onResolved={this.onResolved}
        />
      </div>
    );
  }
}

export default Forgot;
