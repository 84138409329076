import { server, config } from "../env";
import axios from "axios";

export default class Requests {
  async checkAccess() {
    var status = false;
    await axios
      .get(server + "/v1/user/access", config)
      .then((rsp) => {
        status = true;
      })
      .catch((err) => {});
    return status;
  }

  async getAnnouncement() {
    var announcement = null;
    await axios
      .get(server + "/v1/announcement/read", config)
      .then((rsp) => {
        announcement = rsp.data.payload?.text;
      })
      .catch((err) => {});
    return announcement;
  }

  async getCategories() {
    var categories = [];
    await axios
      .get(server + "/v1/category/read", config)
      .then((rsp) => {
        categories = rsp.data.payload;
      })
      .catch((err) => {});
    return categories;
  }

  async getFeedbacks(page = 1, search = "") {
    var feedbacks = [];
    var pager = {};
    await axios
      .post(
        server + "/v1/feedback/readPublic",
        {
          page,
          search,
          sortBy: "updatedAt",
        },
        config
      )
      .then((rsp) => {
        feedbacks = rsp.data.payload.records;
        pager = rsp.data.payload.pager;
      })
      .catch((err) => {});
    return { feedbacks, pager };
  }

  async sendFeedback(orderId, productId, productOptionId, stars, message) {
    var error = null;
    var msg = "";
    await axios
      .post(
        server + "/v1/feedback/create",
        {
          orderId,
          productId,
          productOptionId,
          stars,
          message,
        },
        config
      )
      .then((rsp) => {
        msg = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          if (err.response?.status === 401) {
            msg = "Please Register to leave feedback";
          } else {
            msg = err.response.data.message;
          }
        }
      });

    return { error, msg };
  }

  async getProfile() {
    var profile = {
      balance: 0.0,
    };
    await axios
      .get(server + "/v1/user/profile", config)
      .then((rsp) => {
        profile = rsp.data.payload;
      })
      .catch((err) => {});
    return profile;
  }

  async getPaymentGateways() {
    var paymentGateways = [];
    await axios
      .get(server + "/v1/payment_gateway/read", config)
      .then((rsp) => {
        paymentGateways = rsp.data.payload;
      })
      .catch((err) => {});
    return paymentGateways;
  }

  async getFaqs() {
    var faqs = [];
    await axios
      .get(server + "/v1/faq/read", config)
      .then((rsp) => {
        faqs = rsp.data.payload;
      })
      .catch((err) => {});
    return faqs;
  }

  async getSocialMediaLinks() {
    var socialMediaLinks = [];
    await axios
      .get(server + "/v1/social_link/read", config)
      .then((rsp) => {
        socialMediaLinks = rsp.data.payload;
      })
      .catch((err) => {});
    return socialMediaLinks;
  }

  async getBalanceSheet() {
    var balanceSheet = [];
    await axios
      .get(server + "/v1/balance/read", config)
      .then((rsp) => {
        balanceSheet = rsp.data.payload;
      })
      .catch((err) => {});
    return balanceSheet;
  }

  async getProducts() {
    var products = [];
    await axios
      .get(server + "/v1/product/read", config)
      .then((rsp) => {
        products = rsp.data.payload;
      })
      .catch((err) => {});
    return products;
  }

  async getProductOptions(id) {
    var productOptions = [];
    await axios
      .get(server + "/v1/product/option/read/" + id, config)
      .then((rsp) => {
        productOptions = rsp.data.payload;
      })
      .catch((err) => {});
    return productOptions;
  }

  async getProductOption(id) {
    var productOption = {};
    await axios
      .get(server + "/v1/product/option/readOne/" + id, config)
      .then((rsp) => {
        productOption = rsp.data.payload;
      })
      .catch((err) => {});
    return productOption;
  }

  async setProductToCart(optionId, quantity, duration, localConfig = config) {
    var error = null;
    var message = "";
    await axios
      .post(
        server + "/v1/cart/add",
        {
          optionId,
          quantity,
          duration,
        },
        localConfig
      )
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    if (error) {
      var cart = localStorage.getItem("cart");
      if (cart) {
        cart = JSON.parse(cart);
        cart.push({ optionId, quantity, duration });
        localStorage.setItem("cart", JSON.stringify(cart));
      } else {
        localStorage.setItem(
          "cart",
          JSON.stringify([{ optionId, quantity, duration }])
        );
      }
      error = false;
      message = "Product added to cart";
    }

    return { error, message };
  }

  async getCartItems() {
    var cartItems = [];
    var cartPrice = 0;
    await axios
      .get(server + "/v1/cart/read", config)
      .then((rsp) => {
        cartItems = rsp.data.payload.cart;
        cartPrice = rsp.data.payload.price;
      })
      .catch(async (err) => {
        var cart = localStorage.getItem("cart");
        if (cart) {
          cart = JSON.parse(cart);
          const productOptions = [];

          for (var i = 0; i < cart.length; i++) {
            var productOption = await this.getProductOption(cart[i].optionId);

            var subOption = productOption.subOptions.find(
              (subOption) => subOption.duration === cart[i].duration
            );

            if (subOption) {
              cartPrice += subOption.price * cart[i].quantity;
              productOptions.push({
                option: productOption,
                quantity: cart[i].quantity,
                duration: cart[i].duration,
              });
            }
          }
          cartItems = {
            productOptions,
          };
        } else cartItems = {};
      });

    return {
      cartItems: JSON.parse(JSON.stringify(cartItems)),
      cartPrice,
    };
  }

  async removeCartItem(id) {
    var error = null;
    var message = "";
    await axios
      .delete(server + "/v1/cart/remove/" + id, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        var cart = localStorage.getItem("cart");
        if (cart) {
          cart = JSON.parse(cart);
          cart = cart.filter((item) => item.optionId !== id);
          localStorage.setItem("cart", JSON.stringify(cart));
        }
      });

    return { error, message };
  }

  async updateCartItem(optionId, quantity) {
    var error = null;
    var message = "";
    await axios
      .put(server + "/v1/cart/update", { optionId, quantity }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        // update local storage cart
        var cart = localStorage.getItem("cart");
        if (cart) {
          cart = JSON.parse(cart);
          cart = cart.map((item) => {
            if (item.optionId === optionId) {
              item.quantity = quantity;
            }
            return item;
          });
          localStorage.setItem("cart", JSON.stringify(cart));
        }
      });

    return { error, message };
  }

  async clearCart() {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/cart/clear", {}, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        localStorage.removeItem("cart");
        localStorage.setItem("cart", JSON.stringify([]));
      });
    return { error, message };
  }

  async applyCouponOnCart(coupon) {
    var error = null;
    var message = "";
    var price = 0;
    await axios
      .post(server + "/v1/cart/applyCoupon", { coupon }, config)
      .then((rsp) => {
        message = rsp.data.message;
        price = rsp.data.payload;
      })
      .catch(async (err) => {
        if (!(await this.checkAccess())) {
          const cartItems = await this.getCartItems();
          await axios
            .post(
              server + "/v1/cart/applyCouponPublic",
              {
                cartItems: cartItems.cartItems,
                coupon,
              },
              config
            )
            .then((rsp) => {
              message = rsp.data.message;
              price = rsp.data.payload;
            })
            .catch((err) => {
              error = true;
              message = err.response.data.message;
            });
        } else {
          error = true;
          message = err.response.data.message;
        }
      });
    return { error, message, price };
  }

  async sendLocalCart(localConfig = config) {
    const cart = localStorage.getItem("cart");
    if (cart) {
      const cartItems = JSON.parse(cart);
      for (var i = 0; i < cartItems.length; i++) {
        await this.setProductToCart(
          cartItems[i].optionId,
          cartItems[i].quantity,
          cartItems[i].duration,
          localConfig
        );
      }
      localStorage.removeItem("cart");
      return true;
    }
    return false;
  }

  async checkout(
    coupon,
    paymentMethod,
    msg,
    localConfig = config,
    isGuest = false
  ) {
    var error = null;
    var message = "";
    var payload = {};
    await axios
      .post(
        server + "/v1/order/create",
        { coupon, paymentMethod, message: msg, isGuest },
        localConfig
      )
      .then((rsp) => {
        message = rsp.data.message;
        payload = rsp.data.payload;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message =
            typeof err.response.data === "string"
              ? err.response.data
              : err.response.data.message;
        }
      });
    return { error, message, payload };
  }

  async getOrder(id) {
    var order = null;
    await axios
      .get(server + "/v1/order/read/" + id, config)
      .then((rsp) => {
        order = rsp.data.payload;
      })
      .catch((err) => {});
    return order;
  }

  async getTickets(page = 1, search = "") {
    var tickets = [];
    var pager = {};
    await axios
      .post(
        server + "/v1/ticket/read",
        {
          page,
          search,
          sortBy: "updatedAt",
        },
        config
      )
      .then((rsp) => {
        tickets = rsp.data.payload.records;
        pager = rsp.data.payload.pager;
      })
      .catch((err) => {});
    return { tickets, pager };
  }

  async createTicket(orderId, subject, msg) {
    var error = null;
    var message = "";
    await axios
      .post(
        server + "/v1/ticket/create",
        { orderId, subject, message: msg },
        config
      )
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async sendTicketMessage(ticketId, msg) {
    var error = null;
    var message = "";
    await axios
      .post(
        server + "/v1/ticket/sendMessage",
        { ticketId, message: msg },
        config
      )
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async closeTicket(ticketId) {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/ticket/close", { ticketId }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async openTicket(ticketId) {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/ticket/open", { ticketId }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async getOrderRequests(orderId, productId, productOptionId) {
    var requests = [];
    await axios
      .post(
        server + "/v1/request/getRequests",
        { orderId, productId, productOptionId },
        config
      )
      .then((rsp) => {
        requests = rsp.data.payload;
      })
      .catch((err) => {});
    return requests;
  }

  async requestReplacement(
    orderId,
    productId,
    productOptionId,
    msg,
    index,
    recaptcha
  ) {
    var error = null;
    var message = "";
    await axios
      .post(
        server + "/v1/request/createReplacement",
        {
          orderId,
          productId,
          productOptionId,
          message: msg,
          index,
          "g-recaptcha-response": recaptcha,
        },
        config
      )
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        error = true;
        if (typeof err.response.data !== "string") {
          message = err.response.data.message;
        } else {
          message = err.response.data;
        }
      });

    return { error, message };
  }

  async createReplacementRequest(orderId, productId, productOptionId, msg) {
    var error = null;
    var message = "";
    await axios
      .post(
        server + "/v1/request/createRequest",
        { orderId, productId, productOptionId, message: msg },
        config
      )
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response?.data?.message;
        }
      });

    return { err: error, msg: message };
  }

  async updateUsername(username) {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/user/update/username", { username }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async updateEmail(email) {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/user/update/email", { email }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async updatePassword(password) {
    var error = null;
    var message = "";
    await axios
      .post(server + "/v1/user/update/password", { password }, config)
      .then((rsp) => {
        message = rsp.data.message;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }

  async getOrders(page = 1, search = "") {
    var orders = [];
    var pager = {};
    await axios
      .post(
        server + "/v1/order/read",
        {
          page,
          search,
          sortBy: "createdAt",
        },
        config
      )
      .then((rsp) => {
        orders = rsp.data.payload.records;
        pager = rsp.data.payload.pager;
      })
      .catch((err) => {});
    return { orders, pager };
  }

  async readDocs() {
    var doc = {};
    await axios
      .get(server + "/v1/doc/read", config)
      .then((rsp) => {
        doc = rsp.data.payload;
      })
      .catch((err) => {});
    return doc;
  }

  async redeemKey(email, key, recaptcha) {
    var error = null;
    var message = "";
    await axios
      .post(
        server + "/v1/order/redeemKey",
        { email, key, "g-recaptcha-response": recaptcha },
        config
      )
      .then((rsp) => {
        message = rsp.data.message;
        window.location.href = "/find-order?id=" + rsp.data.payload.orderId;
      })
      .catch((err) => {
        if (err.response) {
          error = true;
          message = err.response.data.message;
        }
      });

    return { error, message };
  }
}
