import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Requests from "../utils/requests";
import { notify } from "../utils/helper";
import Loader from "../components/Loader";
import Modal from "../components/Modal";

const request = new Requests();

class Ticket extends PureComponent {
  state = {
    id: window.location.href.split("orderId=").pop(),
    tickets: [],
    pager: {},

    ticket: {},

    loader: "",
    search: "",
  };

  componentDidMount = () => {
    this.readTickets();

    setInterval(() => {
      const chatArea = document.getElementById("chat");
      if (chatArea) {
        chatArea.scrollBy(chatArea.scrollHeight, chatArea.scrollHeight);
      }
    }, 1000);
  };

  readTickets = async (page = 1, search = this.state.search) => {
    const { tickets, pager } = await request.getTickets(page, search);
    this.setState({
      tickets,
      pager,
    });
  };

  createTicket = async (e) => {
    e.preventDefault();
    this.setState({ loader: <Loader /> });

    const form = e.target;
    const { error, message } = await request.createTicket(
      form.orderId.value,
      form.subject.value,
      form.message.value
    );

    notify(error ? "error" : "success", message);
    this.setState({ loader: "" });
    this.readTickets();
  };

  sendMessage = async (e) => {
    e.preventDefault();
    this.setState({ loader: <Loader /> });

    const form = e.target;
    const { error, message } = await request.sendTicketMessage(
      this.state.ticket._id,
      form.message.value
    );

    notify(error ? "error" : "success", message);
    this.setState({ loader: "" });
    this.readTickets();
  };

  openTicket = async (id) => {
    this.setState({ loader: <Loader /> });

    const { error, message } = await request.openTicket(id);

    notify(error ? "error" : "success", message);
    this.setState({ loader: "" });
    this.readTickets();
  };

  closeTicket = async (id) => {
    this.setState({ loader: <Loader /> });

    const { error, message } = await request.closeTicket(id);

    notify(error ? "error" : "success", message);
    this.setState({ loader: "" });
    this.readTickets();
  };

  render() {
    const { id, loader, tickets, pager, ticket } = this.state;

    return (
      <section className="dashboard-main-section mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="dahboard-home-head">
                        <h5>Tickets</h5>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="dahboard-home-head text-end">
                        <Link
                          to="/shop"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Explore Shop <i className="fas fa-arrow-right"></i>{" "}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="dashboard-ticket-area">
                    <div className="d-flex">
                      <h6>Create a Support Ticket</h6>
                      <h6>Our Support replies within 24 hours</h6>
                    </div>

                    <form onSubmit={this.createTicket}>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Order Id"
                          className="form-control"
                          name="orderId"
                          defaultValue={!id.includes("ticket") ? id : ""}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Ticket Subject"
                          className="form-control"
                          name="subject"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          placeholder="Your Message here"
                          className="form-control"
                          name="message"
                          required
                        ></textarea>
                        <button type="submit" className="btn btn-submit">
                          Create Ticket {loader}
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="dashboard-table-head">
                        <h5>Your Tickets ({pager.total})</h5>
                        <div className="text-end search-boxx">
                          <i className="fas fa-search"></i>
                          <input
                            type="text"
                            placeholder="Search tickets"
                            onChange={(e) => {
                              this.setState({ search: e.target.value });
                              this.readTickets(1, e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="dashboard-table-wrap ticket-table-wrap">
                        <table>
                          <thead>
                            <tr>
                              <th width="7%">#</th>
                              <th width="24%">SUBJECT</th>
                              <th width="12%">REPLIES</th>
                              <th>TICKET STATUS</th>
                              <th>DATE OPENED</th>
                              <th>ACTION </th>
                            </tr>
                          </thead>
                          <tbody>
                            {tickets?.map((ticket, index) => (
                              <tr>
                                <td>
                                  <p>{index + 1}</p>
                                </td>
                                <td>
                                  <p>{ticket.subject.substr(0, 40)}...</p>
                                </td>
                                <td>
                                  {ticket.messages[ticket.messages.length - 1]
                                    .username === "Admin" ? (
                                    <p style={{ color: "#038AED" }}>
                                      Staff Replied
                                    </p>
                                  ) : (
                                    <p>You Replied</p>
                                  )}
                                </td>
                                <td>
                                  <p>
                                    {ticket.status === "closed" && (
                                      <span className="category_orange active">
                                        Closed
                                      </span>
                                    )}
                                    {ticket.status === "open" && (
                                      <span className="category_orange">
                                        Active
                                      </span>
                                    )}
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    {new Date(ticket.createdAt).toDateString()}
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        this.setState({
                                          ticket,
                                        });
                                      }}
                                      data-bs-toggle="modal"
                                      data-bs-target="#viewMessages"
                                    >
                                      Read{" "}
                                      <i className="fas fa-arrow-right"></i>
                                    </Link>
                                  </p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <nav className="mt-3">
                        <ul className="pagination justify-content-center">
                          {pager.previous && (
                            <li
                              className="page-item bg-light rounded"
                              onClick={() =>
                                this.readTickets(
                                  pager.previous,
                                  null,
                                  pager.sortBy
                                )
                              }
                              style={{
                                height: "38px",
                              }}
                            >
                              <Link
                                to="#"
                                className="page-link h-100"
                                style={{
                                  paddingTop: "10px",
                                }}
                              >
                                <i className="bi bi-chevron-double-left"></i>
                              </Link>
                            </li>
                          )}

                          {pager.previous && (
                            <li
                              className="page-item bg-light rounded"
                              onClick={() =>
                                this.readTickets(
                                  pager.previous,
                                  null,
                                  pager.sortBy
                                )
                              }
                            >
                              <Link to="#" className="page-link">
                                {pager.page - 1}
                              </Link>
                            </li>
                          )}

                          <li className="page-item bg-primary rounded">
                            <Link to="#" className="page-link" href="#">
                              {pager.page}
                            </Link>
                          </li>

                          {pager.next && (
                            <li
                              className="page-item bg-light rounded"
                              onClick={() =>
                                this.readTickets(pager.next, null, pager.sortBy)
                              }
                            >
                              <Link to="#" className="page-link">
                                {pager.page + 1}
                              </Link>
                            </li>
                          )}

                          {pager.next && (
                            <li
                              className="page-item bg-light rounded"
                              onClick={() =>
                                this.readTickets(pager.next, null, pager.sortBy)
                              }
                              style={{
                                height: "38px",
                              }}
                            >
                              <Link
                                to="#"
                                className="page-link h-100"
                                style={{
                                  paddingTop: "10px",
                                }}
                              >
                                <i className="bi bi-chevron-double-right"></i>
                              </Link>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal id="viewMessages" title={"Ticket Messages - " + ticket?.subject}>
          <div className="modal-body">
            {/* messages */}
            <div className="card border-0 mb-3">
              <div
                className="card-body"
                style={{
                  height: "400px",
                  overflowY: "auto",
                }}
                id="chat"
              >
                {ticket?.messages?.map((message) => (
                  <div key={message._id}>
                    <div
                      className={
                        "row " +
                        (message.username === "Admin"
                          ? "justify-content-start text-start"
                          : "justify-content-end text-end")
                      }
                    >
                      <div
                        className="col-auto"
                        style={{
                          maxWidth: "80%",
                        }}
                      >
                        <div
                          className={`card br-15px border bg-${
                            message.username === "Admin" ? "light" : "primary"
                          } mb-2`}
                        >
                          <div
                            className={`card-body pb-1 px-2 pt-1 ${
                              message.username === "Admin"
                                ? "text-dark"
                                : "text-light"
                            }`}
                          >
                            {message.message}
                            <p
                              className="p-0 m-0"
                              style={{
                                fontSize: "8px",
                              }}
                            >
                              {new Date(message.timestamp).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {ticket.status === "open" && <hr />}

            {ticket.status === "open" && (
              <form onSubmit={this.sendMessage}>
                <div className="form-group mb-3">
                  <label htmlFor="name">Write Message</label>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="3"
                    className="form-control br-15px"
                  ></textarea>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 br-15px py-3"
                  >
                    Send Message {loader}
                  </button>
                </div>
              </form>
            )}
          </div>
        </Modal>
      </section>
    );
  }
}

export default Ticket;
