import React, { PureComponent } from "react";
import Requests from "../utils/requests";
import { notify } from "../utils/helper";
import { RECAPTCHA_KEY } from "../env";
import Loader from "../components/Loader";
import Recaptcha from "react-google-invisible-recaptcha";
const requests = new Requests();

class Redeem extends PureComponent {
  state = {
    email: localStorage.getItem("email"),
    loader: false,
    reCaptcha: null,
  };

  redeemKey = async (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    await this.recaptcha.execute();
    const { error, message } = await requests.redeemKey(
      e.target.email?.value || this.state.value,
      e.target.key.value,
      await this.recaptcha.getResponse()
    );
    notify(error ? "error" : "success", message);
    this.setState({ loader: false });
  };

  onResolved = async () => {
    this.setState({ reCaptcha: this.recaptcha.getResponse() });
  };

  render() {
    const { loader, email } = this.state;
    return (
      <section className="find-order-area mb-18">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="find-order-empty">
                <h1>
                  Redeem <span>Key</span>
                </h1>
                <p>
                  An email (Check Spam) will be sent to you with an Order ID.
                  You can later use the <br /> "Find Order" button to see your
                  account details and manage your replacements.
                </p>

                <div className="redeem-form-wrap">
                  <form onSubmit={this.redeemKey}>
                    <div className="form-group">
                      <label for="">Email Address</label>
                      <input
                        type="text"
                        name="email"
                        placeholder="Enter email address"
                        className="form-control"
                        value={email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label for="">Redeem Key</label>
                      <input
                        type="text"
                        name="key"
                        placeholder="Enter Redeem Key"
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-bttn">
                      <button type="submit" className="btn btn-submit">
                        Redeem {loader && <Loader />}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Recaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={RECAPTCHA_KEY}
          onResolved={this.onResolved}
        />
      </section>
    );
  }
}

export default Redeem;
