import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import { RECAPTCHA_KEY, server } from "../env";
import { notify, generateRadomString } from "../utils/helper";
import Requests from "../utils/requests";
import Loader from "./Loader";
import Recaptcha from "react-google-invisible-recaptcha";
import axios from "axios";
import Alert from "./Alert";
const requests = new Requests();

class Cart extends PureComponent {
  state = {
    price: 0,
    statePrice: false,
    coupon: "",
    paymentGateways: [],
    paymentMethod: "coinbase",
    loader: "",
    products: [],
    isLoggedIn: false,
    email: "",
    reCaptcha: null,
    applyLoader: "",

    checkoutMessage: "",
    isCouponApplied: false,
  };

  componentDidMount = async () => {
    const access = await requests.checkAccess();

    if (access) {
      await requests.sendLocalCart();
      this.props.refreshCart();
    }
    this.setState({ isLoggedIn: access });

    this.setState({
      paymentGateways: await requests.getPaymentGateways(),
      products: await requests.getProducts(),
    });
  };

  removeItem = async (id) => {
    await requests.removeCartItem(id);
    this.props.refreshCart();
  };

  updateItem = async (id, quantity) => {
    const { error, message } = await requests.updateCartItem(id, quantity);
    if (error) notify("error", message);
    else this.props.refreshCart();
  };

  clearCart = async () => {
    await requests.clearCart();
    this.props.refreshCart();
  };

  applyCoupon = async () => {
    if (this.state.coupon === "") {
      this.setState({
        statePrice: false,
      });
      this.props.refreshCart();
      return;
    }
    this.setState({
      applyLoader: <Loader />,
    });
    const { error, message, price } = await requests.applyCouponOnCart(
      this.state.coupon
    );

    if (error) notify("error", message);
    else {
      this.setState({
        price: parseFloat(price),
        statePrice: true,
      });

      notify("success", message);
    }
    this.setState({
      applyLoader: "",
    });
  };

  checkout = async () => {
    this.setState({ loader: <Loader /> });
    const { paymentMethod, coupon, checkoutMessage } = this.state;

    const { error, message, payload } = await requests.checkout(
      coupon,
      paymentMethod,
      checkoutMessage
    );

    if (!error) {
      if (
        paymentMethod === "coinbase" ||
        paymentMethod === "stripe" ||
        paymentMethod === "shoppy"
      ) {
        window.location.href = payload.paymentUrl;
      } else if (paymentMethod === "balance") {
        window.location.href = "/find-order?id=" + payload.orderId;
      }
    } else {
      notify("error", message);
    }
    this.setState({ loader: "" });
  };

  guestCheckout = async () => {
    const email = this.state.email;
    // check if email is valid
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      notify("error", "Invalid email");
      return;
    }
    this.setState({ loader: <Loader /> });
    await this.recaptcha.execute();

    await axios
      .post(server + "/v1/user/register", {
        username: email,
        email: email,
        password: await generateRadomString(10),
        "g-recaptcha-response": await this.recaptcha.getResponse(),
      })
      .then(async (rsp) => {
        const localConfig = {
          headers: {
            Authorization: "Bearer " + rsp.data.payload.token,
          },
        };
        await requests.sendLocalCart(localConfig);

        const { paymentMethod, coupon, checkoutMessage } = this.state;
        const { error, message, payload } = await requests.checkout(
          coupon,
          paymentMethod,
          checkoutMessage,
          localConfig,
          true
        );

        if (!error) {
          if (
            paymentMethod === "coinbase" ||
            paymentMethod === "stripe" ||
            paymentMethod === "shoppy"
          ) {
            window.location.href = payload.paymentUrl;
          }
        } else {
          notify("error", message);
        }
      })
      .catch((err) => {
        if (err.response) {
          notify("error", err.response?.data?.message);
        }
      });
    this.setState({ loader: "" });
  };

  onResolved = async () => {
    this.setState({ reCaptcha: this.recaptcha.getResponse() });
  };

  render() {
    const price = this.state.statePrice
      ? this.state.price
      : this.props.cartPrice || 0;

    const { paymentGateways, paymentMethod, loader } = this.state;
    const { isLoggedIn, applyLoader } = this.state;

    return (
      <div className="main-cart-wrap">
        <div
          className="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header">
            <h5>Your Cart</h5>
            <Link
              to="#"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="close"
            >
              <img
                src="/assets/images/close-ic.svg"
                alt="X"
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="offcanvas-body p-0">
            <div className="cart-txt-wrap">
              <div className="cart-head-wrap">
                <h5>
                  Subtotal :{" "}
                  <span className="text-dark">${price?.toFixed(2) || 0.0}</span>
                </h5>
                <Link to="#" onClick={this.clearCart}>
                  Clear Cart
                </Link>
              </div>

              {this.props.cartItems?.productOptions?.length > 0 &&
                this.props.cartItems?.productOptions?.map((item, index) => (
                  <Fragment key={item._id}>
                    <div className="cart-items-wrap">
                      <div className="media">
                        <img
                          src={server + item?.option?.product?.picture}
                          alt="Netflix"
                          className="img-fluid"
                          style={{
                            width: "73px",
                            height: "53px",
                            borderRadius: "10px",
                          }}
                        />
                        <div className="media-body">
                          <h6>{item?.option?.product?.title}</h6>
                          <p>{item.option.title}</p>
                        </div>
                      </div>
                      <div className="item-price">
                        <h5>
                          $
                          {item.option.subOptions
                            .find((so) => so.duration === item.duration)
                            ?.price?.toFixed(2)}
                        </h5>
                      </div>
                    </div>
                    <div className="cart-item-remov-quanty">
                      <Link
                        to="#"
                        onClick={() => this.removeItem(item.option._id)}
                      >
                        Remove Item
                      </Link>
                      <div className="quantity-counter-wrap">
                        <ul>
                          <li>
                            <span>QTY :</span>
                          </li>
                          <li
                            onClick={() => {
                              this.updateItem(
                                item.option._id,
                                item?.option?.product?.minQuantity <=
                                  item.quantity - 1
                                  ? item.quantity - 1
                                  : item.optionproduct?.minQuantity
                              );
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <i className="fas fa-minus"></i>
                          </li>
                          <li>
                            <input
                              type="text"
                              value={item.quantity}
                              min={item?.option?.product?.minQuantity}
                              max={item?.option?.product?.maxQuantity}
                            />
                          </li>
                          <li
                            onClick={() => {
                              this.updateItem(
                                item.option._id,
                                item?.option?.product?.maxQuantity >=
                                  item.quantity + 1
                                  ? item.quantity + 1
                                  : item.quantity
                              );
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <i className="fas fa-plus"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Fragment>
                ))}

              <div className="cupon-head-wrap">
                <h6>Coupon Code</h6>
              </div>
              <div className="cupon-enter-wrap">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Code"
                  onChange={(e) =>
                    this.setState({
                      coupon: e.target.value,
                    })
                  }
                />
                <Link to="#" onClick={this.applyCoupon}>
                  {applyLoader ? (
                    applyLoader
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-arrow-right-short"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                      />
                    </svg>
                  )}
                </Link>
              </div>

              {/* guest checkout */}
              {!isLoggedIn && (
                <Fragment>
                  <div
                    className="cupon-head-wrap pt-4 mt-4"
                    style={{ borderTop: "2px solid rgba(36, 36, 36, 0.1)" }}
                  >
                    <h6>Email Address*</h6>
                  </div>
                  <div className="cupon-enter-wrap">
                    <input
                      type="text"
                      name="email"
                      className="form-control w-100"
                      placeholder="Enter your Email Address"
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </div>
                  <div className="cupon-btttns">
                    <span>or</span>
                    <Link
                      to="/login"
                      onClick={() => {
                        document.getElementById("close").click();
                      }}
                    >
                      Sign In
                    </Link>
                    <Link
                      to="/signup"
                      className="create"
                      onClick={() => {
                        document.getElementById("close").click();
                      }}
                    >
                      Create Account
                    </Link>
                  </div>
                </Fragment>
              )}

              {/* additional message */}
              <div
                className="cupon-head-wrap pt-4 mt-4"
                style={{ borderTop: "2px solid rgba(36, 36, 36, 0.1)" }}
              >
                <h6>Message to Admin</h6>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="3"
                  className="form-control"
                  placeholder="Type message..."
                  style={{
                    borderRadius: "10px",
                  }}
                  onChange={(e) =>
                    this.setState({
                      checkoutMessage: e.target.value,
                    })
                  }
                ></textarea>
              </div>

              {/* Payment methods */}
              <div
                className="cupon-head-wrap pt-4 mt-4"
                style={{ borderTop: "2px solid rgba(36, 36, 36, 0.1)" }}
              >
                <h6>Payment Method</h6>
              </div>

              <Alert
                className="info"
                message="Check your email (inbox or spam) after payment for order INFO"
              />

              <div className="payment-method-wrap mt-2">
                {paymentGateways.find((gateway) => gateway.name === "coinbase")
                  ?.status && (
                  <div
                    className={
                      paymentMethod === "coinbase"
                        ? "payment-method-box active w-100"
                        : "payment-method-box w-100"
                    }
                    onClick={() => this.setState({ paymentMethod: "coinbase" })}
                  >
                    <img
                      src="/assets/images/bitcoin.svg"
                      alt="Bitcoin"
                      className="img-fluid"
                    />
                    <p>Crypto</p>
                  </div>
                )}
                {paymentGateways.find((gateway) => gateway.name === "shoppy")
                  ?.status && (
                  <div
                    className={
                      paymentMethod === "shoppy"
                        ? "payment-method-box active w-100"
                        : "payment-method-box w-100"
                    }
                    onClick={() => this.setState({ paymentMethod: "shoppy" })}
                  >
                    <img
                      src="/assets/images/pypl.svg"
                      alt="Bitcoin"
                      className="img-fluid"
                    />
                    <p>Paypal</p>
                  </div>
                )}
                {paymentGateways.filter(
                  (gateway) => gateway.name === "balance" && isLoggedIn
                ).length > 0 && (
                  <div
                    className={
                      paymentMethod === "balance"
                        ? "payment-method-box active w-100"
                        : "payment-method-box w-100"
                    }
                    onClick={() => this.setState({ paymentMethod: "balance" })}
                  >
                    <img
                      src="/assets/images/blnce-icon.svg"
                      alt="Bitcoin"
                      className="img-fluid"
                    />
                    <p>Balance</p>
                  </div>
                )}
              </div>

              <div
                className="payment-method-wrap mt-2"
                style={{ display: "block" }}
              >
                {paymentGateways.find((gateway) => gateway.name === "stripe")
                  ?.status && (
                  <div
                    className={
                      paymentMethod === "stripe"
                        ? "payment-method-box active w-100"
                        : "payment-method-box w-100"
                    }
                    onClick={() => this.setState({ paymentMethod: "stripe" })}
                  >
                    <img
                      src="/assets/images/blnce-icon.svg"
                      alt="Bitcoin"
                      className="img-fluid"
                    />
                    <p>Credit/Debit Card</p>
                  </div>
                )}
              </div>
              <div className="total-bttn-wrap">
                <button
                  type="submit"
                  id="btn-sub"
                  className="btn btn-submit"
                  onClick={() => {
                    isLoggedIn ? this.checkout() : this.guestCheckout();
                  }}
                >
                  <span>Checkout {loader} </span>{" "}
                  <span>
                    ${price?.toFixed(2)} <i className="fas fa-arrow-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Recaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={RECAPTCHA_KEY}
          onResolved={this.onResolved}
        />
      </div>
    );
  }
}

export default Cart;
