import React, { PureComponent } from "react";
import axios from "axios";
import { server, RECAPTCHA_KEY } from "../env";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { notify } from "../utils/helper";
import Recaptcha from "react-google-invisible-recaptcha";
const Cookies = require("js-cookie");

class Signup extends PureComponent {
  state = {
    loader: false,
  };

  componentDidMount = () => {
    var password = document.getElementById("password");
    var label = document.getElementById("label");
    var check = document.getElementById("check");

    check.onclick = function () {
      if (check.checked) {
        password.setAttribute("type", "text");
        label.innerHTML = "HIDE";
      } else {
        password.setAttribute("type", "password");
        label.innerHTML = "SHOW";
      }
    };
  };

  signup = async (e) => {
    e.preventDefault();
    await this.recaptcha.execute();

    const form = e.target;
    if (form.password.value !== form.password2.value) {
      notify("error", "Passwords do not match");
      return;
    }

    this.setState({ loader: true });
    const data = new FormData(form);
    data.append("g-recaptcha-response", await this.recaptcha.getResponse());

    await axios
      .post(server + "/v1/user/register", data)
      .then((rsp) => {
        Cookies.set("token", rsp.data.payload.token);
        notify("success", rsp.data.message);
        window.location.href = "/";
      })
      .catch((err) => {
        if (err.response) {
          notify("error", err.response?.data?.message);
        }
      });
    this.setState({ loader: false });
  };

  onResolved = async () => {
    this.setState({ reCaptcha: this.recaptcha.getResponse() });
  };

  render() {
    const loader = this.state.loader;

    return (
      <div className="main-wrap-login" style={{ backgroundColor: "#FAFAFA" }}>
        <section className="signup-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="sign-up-txt-wrap">
                  <h4>Why Choose Limitless</h4>

                  <div className="why-choice-box">
                    <div className="media">
                      <img
                        src="/assets/images/choice-1.svg"
                        alt="Choice"
                        className="img-fluid"
                      />
                      <div className="media-body">
                        <h6>Lightning Fast AutoReplacement</h6>

                        <p>
                          No more waiting times. Need a <br /> replacement? Just
                          click a button!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="why-choice-box">
                    <div className="media">
                      <img
                        src="/assets/images/choice-2.svg"
                        alt="Choice"
                        className="img-fluid"
                      />
                      <div className="media-body">
                        <h6>Highly Secure Transactions</h6>

                        <p>
                          We ensure full security and anonymity of all placed
                          orders
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="why-choice-box">
                    <div className="media">
                      <img
                        src="/assets/images/choice-3.svg"
                        alt="Choice"
                        className="img-fluid"
                      />
                      <div className="media-body">
                        <h6>Best Prices on the Accounts</h6>

                        <p>
                          Industry leading quality, unmatched by no other, only
                          #1 Premium Accounts
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="why-choice-box mb-0">
                    <div className="media">
                      <img
                        src="/assets/images/choice-4.svg"
                        alt="Choice"
                        className="img-fluid"
                      />
                      <div className="media-body">
                        <h6>Industry Leading Support</h6>

                        <p>
                          Renowned for our top quality support, we will never
                          let you down
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="signup-form-wrap">
                  <form onSubmit={this.signup}>
                    <div className="d-flex">
                      <h1>Create Account</h1>
                      <Link to="/login">
                        <span>or </span> Sign In
                      </Link>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Username</label>
                      <input
                        type="text"
                        className="form-control"
                        name="username"
                        placeholder="Create a unique username"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Email Address</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter your email address"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Password</label>
                      <input
                        id="password"
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Set a strong password"
                        required
                      />
                      <input id="check" className="check" type="checkbox" />
                      <label id="label" htmlFor="check">
                        SHOW
                      </label>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Confirm Password</label>
                      <input
                        id="password2"
                        type="password"
                        name="password2"
                        className="form-control"
                        placeholder="Confirm your password"
                        required
                      />
                    </div>
                    <div className="captcha-part"></div>
                    <div className="form-bttn">
                      <button type="submit" className="btn btn_submit">
                        Let's Go {loader && <Loader />}
                      </button>
                    </div>
                    <div className="form-group">
                      <p>
                        You acknowledge that you read, and agree to our <br />{" "}
                        <Link to="/terms-of-service">Terms of Service</Link> and
                        our <Link to="/privacy-policy">Privacy Policy.</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Recaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={RECAPTCHA_KEY}
          onResolved={this.onResolved}
        />
      </div>
    );
  }
}

export default Signup;
