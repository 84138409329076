import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";
import { server, config } from "./env";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./source/Home";
import Feedback from "./source/Feedback";
import FindOrder from "./source/FindOrder";
import Shop from "./source/Shop";
import Redeem from "./source/Redeem";
import FAQ from "./source/FAQ";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import Forgot from "./auth/Forgot";
import Reset from "./auth/Reset";
import Ticket from "./source/Ticket";
import Balance from "./source/Balance";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsOfService from "./source/ToS";
import PrivacyPolicy from "./source/PrivacyPolicy";
import Settings from "./source/Settings";
import Dashboard from "./source/Dashboard";

function App() {
  const headerRef = React.createRef();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    checkLogin();
    window.onload = () => {
      setInterval(() => {
        const location = window.location.href;
        const headerDom = document.getElementById("header");
        const footerDom = document.getElementById("footer");
        if (
          location.includes("terms-of-service") ||
          location.includes("privacy-policy")
        ) {
          if (headerDom) headerDom.style.display = "none";
          if (footerDom) footerDom.style.display = "none";
        } else {
          if (headerDom) headerDom.style.display = "block";
          if (footerDom) footerDom.style.display = "block";
        }
      }, 1000);
    };
  }, [isLoggedIn]);

  const checkLogin = async () => {
    await axios
      .get(server + "/v1/user/access", config)
      .then((rsp) => {
        setIsLoggedIn(true);
        localStorage.setItem("email", rsp.data.payload.email);
      })
      .catch((err) => {
        localStorage.removeItem("email");
      });
  };

  const refreshCart = async () => {
    headerRef.current.refreshCart();
  };

  return (
    <BrowserRouter>
      <Header loggedIn={isLoggedIn} ref={headerRef} />
      <Routes>
        <Route path="/redeem-key" element={<Redeem />} />
        <Route path="/faqs" element={<FAQ />} />
        <Route path="/shop" element={<Shop refreshCart={refreshCart} />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/find-order" element={<FindOrder />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />

        {/* Auth Routes */}
        <Route
          path="/reset"
          element={!isLoggedIn ? <Reset /> : <Navigate replace to="/" />}
        />
        <Route
          path="/forgot"
          element={!isLoggedIn ? <Forgot /> : <Navigate replace to="/" />}
        />
        <Route
          path="/signup"
          element={!isLoggedIn ? <Signup /> : <Navigate replace to="/" />}
        />
        <Route
          path="/login"
          element={!isLoggedIn ? <Login /> : <Navigate replace to="/" />}
        />

        {/* Authenticated Routes */}
        <Route
          path="/dashboard"
          element={
            isLoggedIn ? <Dashboard /> : <Navigate replace to="/login" />
          }
        />
        <Route
          path="/settings"
          element={isLoggedIn ? <Settings /> : <Navigate replace to="/login" />}
        />
        <Route
          path="/tickets"
          element={isLoggedIn ? <Ticket /> : <Navigate replace to="/login" />}
        />
        <Route
          path="/balance"
          element={isLoggedIn ? <Balance /> : <Navigate replace to="/login" />}
        />

        <Route path="/*" element={<Home />} />
      </Routes>
      <Footer loggedIn={isLoggedIn} />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
