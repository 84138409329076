import React, { PureComponent } from "react";
import Requests from "../utils/requests";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";

const requests = new Requests();

class Dashboard extends PureComponent {
  state = {
    profile: {},
    orders: [],
    pager: {
      page: 1,
    },
    loading: true,
  };

  componentDidMount = async () => {
    this.setState({
      profile: await requests.getProfile(),
    });
    this.readOrders();
  };

  readOrders = async (
    page = 1,
    search = this.state.search,
    sortBy = "createdAt"
  ) => {
    this.setState({ loading: true });
    const { orders, pager } = await requests.getOrders(page, search);
    this.setState({
      orders,
      pager,
      loading: false,
    });
  };

  render() {
    const { profile, orders, pager, loading } = this.state;

    return (
      <section className="dashboard-main-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="dahboard-home-head">
                        <h5>
                          Hi {profile.username || "-"} 👋, It's good to see you
                        </h5>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="dahboard-home-head text-end">
                        <Link
                          to="/shop"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Explore Shop <i className="fas fa-arrow-right"></i>{" "}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="dashboard-feature-wrap">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-6">
                        <div className="feature-wrap-box">
                          <div className="media">
                            <img
                              src="./assets/images/lock-icon.svg"
                              alt="lock"
                              className="img-fluid"
                            />
                            <div className="media-body">
                              <p>Your Orders</p>
                              <h4>{profile.orders || "-"}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-6">
                        <div className="feature-wrap-box download-feature">
                          <div className="media">
                            <img
                              src="./assets/images/tag-icon.svg"
                              alt="tag"
                              className="img-fluid"
                            />
                            <div className="media-body">
                              <p>Your Tickets</p>
                              <h4>{profile.tickets || "0"}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-6">
                        <div className="feature-wrap-box edit-feature">
                          <div className="media">
                            <img
                              src="./assets/images/pocket-icon.svg"
                              alt="pockets"
                              className="img-fluid"
                            />
                            <div className="media-body">
                              <p>Your Balance</p>
                              <h4>${profile.balance?.toFixed(2) || "0"}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-6">
                        <div className="feature-wrap-box date-feature">
                          <div className="media">
                            <img
                              src="./assets/images/date-icon.svg"
                              alt="user"
                              className="img-fluid"
                            />
                            <div className="media-body">
                              <p>Account Created</p>
                              <h4 style={{ fontSize: "20px" }}>
                                {profile.created
                                  ? new Date(
                                      profile.created
                                    ).toLocaleDateString()
                                  : "-"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="dashboard-table-head">
                        <h5>Your Orders ({pager.total || 0})</h5>
                        <div className="text-end search-boxx">
                          <i className="fas fa-search"></i>
                          <input
                            type="text"
                            placeholder="Search orders"
                            onChange={(e) => this.readOrders(1, e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="dashboard-table-wrap">
                        <table>
                          <thead>
                            <tr>
                              <th width="20%">#</th>
                              <th>PRODUCTS</th>
                              <th width="7%">PRICE</th>
                              <th>STATUS </th>
                              <th>DATE PURCHASED </th>
                              <th>ACTION </th>
                            </tr>
                          </thead>
                          {!loading && (
                            <tbody>
                              {orders.map((order, index) => (
                                <tr>
                                  <td>
                                    <p>{order._id}</p>
                                  </td>
                                  <td>
                                    <p className="ps-4">
                                      {order.products.length}
                                    </p>
                                  </td>
                                  <td>
                                    <p>${order.price.toFixed(2)}</p>
                                  </td>
                                  <td>
                                    <p>
                                      {order.status === "pending" && (
                                        <span
                                          className="badge ms-2"
                                          style={{
                                            backgroundColor: "#FFEEBE",
                                          }}
                                        >
                                          PENDING
                                        </span>
                                      )}
                                      {order.status === "completed" && (
                                        <span
                                          className="badge ms-2"
                                          style={{
                                            backgroundColor: "#BFECC4",
                                          }}
                                        >
                                          COMPLETED
                                        </span>
                                      )}
                                      {order.status === "partiallyComplete" && (
                                        <span
                                          className="badge bg-info ms-2"
                                          style={{
                                            backgroundColor: "#B6C8FF",
                                          }}
                                        >
                                          PARTIALLY COMPLETE
                                        </span>
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      {new Date(
                                        order.createdAt
                                      ).toLocaleDateString()}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <Link
                                        to={"/find-order?id=" + order._id}
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        View Details{" "}
                                        <i className="fas fa-arrow-right"></i>
                                      </Link>
                                    </p>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}

                          {loading && (
                            <tbody>
                              <tr>
                                <td colSpan="6">
                                  <div className="text-center">
                                    <Loader />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className="col-12">
                      <nav className="mt-3 mb-5">
                        <ul className="pagination justify-content-center">
                          {pager.previous && (
                            <li
                              className="page-item bg-light rounded"
                              onClick={() =>
                                this.readOrders(
                                  pager.previous,
                                  null,
                                  pager.sortBy
                                )
                              }
                              style={{
                                height: "38px",
                              }}
                            >
                              <Link
                                to="#"
                                className="page-link h-100"
                                style={{
                                  paddingTop: "10px",
                                }}
                              >
                                <i className="bi bi-chevron-double-left"></i>
                              </Link>
                            </li>
                          )}

                          {pager.previous && (
                            <li
                              className="page-item bg-light rounded"
                              onClick={() =>
                                this.readOrders(
                                  pager.previous,
                                  null,
                                  pager.sortBy
                                )
                              }
                            >
                              <Link to="#" className="page-link">
                                {pager.page - 1}
                              </Link>
                            </li>
                          )}

                          <li className="page-item bg-primary rounded">
                            <Link to="#" className="page-link" href="#">
                              {pager.page}
                            </Link>
                          </li>

                          {pager.next && (
                            <li
                              className="page-item bg-light rounded"
                              onClick={() =>
                                this.readOrders(pager.next, null, pager.sortBy)
                              }
                            >
                              <Link to="#" className="page-link">
                                {pager.page + 1}
                              </Link>
                            </li>
                          )}

                          {pager.next && (
                            <li
                              className="page-item bg-light rounded"
                              onClick={() =>
                                this.readOrders(pager.next, null, pager.sortBy)
                              }
                              style={{
                                height: "38px",
                              }}
                            >
                              <Link
                                to="#"
                                className="page-link h-100"
                                style={{
                                  paddingTop: "10px",
                                }}
                              >
                                <i className="bi bi-chevron-double-right"></i>
                              </Link>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Dashboard;
