import React, { PureComponent } from "react";
import axios from "axios";
import { server, RECAPTCHA_KEY } from "../env";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { notify } from "../utils/helper";
import Recaptcha from "react-google-invisible-recaptcha";
const Cookies = require("js-cookie");

class Login extends PureComponent {
  state = {
    loader: false,
    reCaptcha: null,
  };

  componentDidMount = () => {
    var password = document.getElementById("password");
    var label = document.getElementById("label");
    var check = document.getElementById("check");

    check.onclick = function () {
      if (check.checked) {
        password.setAttribute("type", "text");
        label.innerHTML = "HIDE";
      } else {
        password.setAttribute("type", "password");
        label.innerHTML = "SHOW";
      }
    };
  };

  login = async (e) => {
    e.preventDefault();
    this.setState({ loader: true });

    await this.recaptcha.execute();

    const form = e.target;
    const data = new FormData(form);
    data.append("g-recaptcha-response", await this.recaptcha.getResponse());

    await axios
      .post(server + "/v1/user/login", data)
      .then((rsp) => {
        Cookies.set("token", rsp.data.payload.token);
        notify("success", rsp.data.message);
        window.location.href = "/";
      })
      .catch((err) => {
        if (err.response) {
          notify("error", err.response?.data?.message);
        }
      });
    this.setState({ loader: false });
  };

  onResolved = async () => {
    this.setState({ reCaptcha: this.recaptcha.getResponse() });
  };

  render() {
    const loader = this.state.loader;

    return (
      <div className="main-wrap-login" style={{ backgroundColor: "#FAFAFA" }}>
        <section className="signup-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6">
                <div className="signup-form-wrap">
                  <form onSubmit={this.login}>
                    <div className="d-flex">
                      <h1>Sign In</h1>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Username/Email</label>
                      <input
                        type="text"
                        name="username"
                        className="form-control"
                        placeholder="Enter your username"
                        required
                        autoFocus
                      />
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <label htmlFor="">Password</label>
                        <Link to="/forgot">Forgot password ?</Link>
                      </div>
                      <input
                        id="password"
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Enter your password"
                        required
                      />
                      <input id="check" className="check" type="checkbox" />
                      <label id="label" htmlFor="check">
                        SHOW
                      </label>
                    </div>
                    <div className="captcha-part"></div>
                    <div className="form-bttn">
                      <button type="submit" className="btn btn_submit">
                        Sign In {loader && <Loader />}
                      </button>
                    </div>
                    <div className="form-group">
                      <p>
                        Not a Member Yet ?{" "}
                        <Link to="/signup" style={{ color: "#2FA4FF" }}>
                          Create Account
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Recaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={RECAPTCHA_KEY}
          onResolved={this.onResolved}
        />
      </div>
    );
  }
}

export default Login;
