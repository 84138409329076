import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import Requests from "../utils/requests";
import { notify, closeModal } from "../utils/helper";
import { server } from "../env";
import Loader from "../components/Loader";
import PageLoader from "../components/PageLoader";
const requests = new Requests();

class Shop extends PureComponent {
  state = {
    isLoaded: false,
    products: [],
    product: {},
    productOptions: [],
    productOption: {},
    categories: [],

    quantity: 1,
    subOptionIndex: null,
    duration: 0,
    loader: "",

    activeCollapseIndex: null,

    search: "",
    category: "",
  };

  componentDidMount = async () => {
    this.setState({
      products: await requests.getProducts(),
      categories: await requests.getCategories(),
      isLoaded: true,
    });
  };

  readProductOptions = async (productId) => {
    var productOptions = await requests.getProductOptions(productId);
    this.setState({
      productOptions,
      productOption: productOptions[0],
    });
  };

  addProductToCart = async (optionId, quantity) => {
    if (!this.state.duration) {
      notify("error", "Select a warranty option");
      return;
    }
    this.setState({ loader: <Loader /> });
    const { error, message } = await requests.setProductToCart(
      optionId,
      quantity,
      this.state.duration
    );
    if (error) notify("error", message);
    this.setState({ loader: "" });
    this.props.refreshCart();
    if (!error && this.state.duration) closeModal("close-modal");
  };

  render() {
    const { isLoaded, products, product, productOptions } = this.state;
    const { quantity, subOptionIndex, duration, activeCollapseIndex, loader } =
      this.state;
    const { productOption, categories } = this.state;
    const { category, search } = this.state;

    return (
      <Fragment>
        {/* Main */}
        <section className="shop-hero-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="shop-hero-txt">
                  <h6>EXPLORE STORE</h6>
                  <h1>
                    SHOP <span>LIMITLESS</span>
                  </h1>
                  <p>Don't settle for less when you have Limitless!</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="product-filter-wrap">
                  <div className="search-filter-wrap">
                    <i className="fas fa-search"></i>
                    <input
                      type="text"
                      placeholder="Search for Products"
                      onChange={(e) =>
                        this.setState({
                          search: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="filter-popup-wrap">
                    <img
                      src="/assets/images/square-icon.svg"
                      alt="Square"
                      className="img-fluid"
                    />
                    <Link to="#" data-bs-toggle="dropdown">
                      Select Category{" "}
                    </Link>
                    <ul class="dropdown-menu p-2">
                      <li>
                        <Link
                          to="#"
                          onClick={() => this.setState({ category: "" })}
                        >
                          All categories
                        </Link>
                      </li>
                      <li>
                        <hr class="dropdown-divider" />
                      </li>
                      {categories.map((category) => (
                        <li>
                          <Link
                            to="#"
                            onClick={() => this.setState({ category })}
                          >
                            {category.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="filter-bttn">
                    <Link to="#">Search</Link>
                  </div>
                </div>
              </div>
            </div>
            {categories
              .filter((cat) => (category ? cat.name === category.name : true))
              .map(
                (category, index) =>
                  products.filter(
                    (p) =>
                      p.categoryIds.includes(category._id) &&
                      (search
                        ? p.title.toLowerCase().includes(search.toLowerCase())
                        : true)
                  ).length > 0 && (
                    <div className="row" key={category._id}>
                      <div className="col-lg-12">
                        <div className="product-list-top">
                          <h4>{category.name}</h4>
                        </div>
                      </div>

                      {products
                        .filter(
                          (p) =>
                            p.categoryIds.includes(category._id) &&
                            (search
                              ? p.title
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              : true)
                        )
                        .map((product) => (
                          <div className="col-lg-3">
                            <div
                              className="product-box-wrap"
                              onClick={() => {
                                this.setState({
                                  product,
                                  quantity: product.minQuantity,
                                  subOptionIndex: null,
                                  activeCollapseIndex: null,
                                });
                                this.readProductOptions(product._id);
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#addToCartModal"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <div
                                className="product-thumbnail-wrap p-0"
                                style={{
                                  background: "unset",
                                }}
                              >
                                <img
                                  src={server + product.picture}
                                  alt="Netflix"
                                  className="img-fluid"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                                <div className="icons">
                                  <Link to="#">
                                    <img
                                      src="/assets/images/icons.svg"
                                      alt="icons"
                                      className="img-fluid"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-7">
                                  <div className="product-details-txt">
                                    <h6>{product.title}</h6>
                                    <span>{product.label}</span>
                                  </div>
                                </div>
                                <div className="col-5">
                                  <div className="product-details-bttn">
                                    <span>from</span>
                                    <p>${product.minPrice}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )
              )}

            <div className="mt-4">{!isLoaded && <PageLoader />}</div>
          </div>
        </section>

        {/* Modals */}
        <section className="modal-section">
          <div className="modal fade" id="addToCartModal" tabindex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5>{product.title}</h5>

                  <img
                    src="/assets/images/close-ic.svg"
                    data-bs-dismiss="modal"
                    alt="X"
                    id="close-modal"
                    className="img-fluid"
                  />
                </div>
                <div className="modal-body">
                  <div className="modal-cart-wrap">
                    <h4>
                      About :{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: product.description?.replaceAll(
                            "\n",
                            "<br/>"
                          ),
                        }}
                      ></span>
                    </h4>

                    {productOptions.map((option, index) => (
                      <div
                        className="cart-modal-item-group"
                        key={option._id + index}
                      >
                        <a
                          data-bs-toggle="collapse"
                          href={"#subOption" + index}
                          role="button"
                          aria-expanded="false"
                          aria-controls={"subOption" + index}
                          onClick={() => {
                            this.setState({
                              activeCollapseIndex: index,
                            });
                          }}
                        >
                          <div className="d-flex align-items-center flex-wrap">
                            <h6> {option.title}</h6>
                            <span
                              className={`badge bg-${
                                option.availableStock === 0
                                  ? "danger"
                                  : "primary"
                              } rounded-lg px-2 mx-3`}
                              style={{
                                borderRadius: "10px",
                              }}
                            >
                              {option.availableStock === 0
                                ? "OUT OF STOCK"
                                : option.availableStock}
                            </span>
                          </div>

                          <i className="fas fa-angle-down"></i>
                        </a>

                        <div
                          className={
                            activeCollapseIndex !== index
                              ? "collapse hidden"
                              : "collapsing"
                          }
                          id={"subOption" + index}
                        >
                          {option.subOptions?.map((subOption, index) => (
                            <div
                              className="modal-cart-items-wrap"
                              key={subOption._id}
                              style={{
                                border:
                                  productOption._id === option._id &&
                                  subOptionIndex === index
                                    ? "2px solid #038aed"
                                    : "",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                option.availableStock > 0 &&
                                  this.setState({
                                    productOption: option,
                                    subOptionIndex: index,
                                    duration: subOption.duration,
                                  });
                              }}
                            >
                              <div className="row align-items-center">
                                <div className="col-8">
                                  <h5>{subOption.title} </h5>
                                </div>
                                <div className="col-4 text-end">
                                  <span className="badge-custom">
                                    ${subOption.price.toFixed(2)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}

                    <div className="row align-items-center">
                      <div className="col-lg-6 mb-3">
                        <div className="quantity-counter-wrap">
                          <ul>
                            <li>
                              <span>QTY :</span>
                            </li>
                            <li
                              onClick={() => {
                                this.setState({
                                  quantity:
                                    product.minQuantity <= quantity - 1
                                      ? quantity - 1
                                      : product.minQuantity,
                                });
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i className="fas fa-minus"></i>
                            </li>
                            <li>
                              <input
                                type="number"
                                name="quantity"
                                value={quantity}
                                onChange={(e) =>
                                  this.setState({
                                    quantity: parseInt(e.target.value),
                                  })
                                }
                                min={product.minQuantity}
                                max={product.maxQuantity}
                                style={{
                                  paddingLeft: "13px",
                                  width: "80px",
                                }}
                                requried
                              />
                            </li>
                            <li
                              onClick={() => {
                                this.setState({
                                  quantity:
                                    product.maxQuantity >= quantity + 1
                                      ? quantity + 1
                                      : quantity,
                                });
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i className="fas fa-plus"></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="cart-bttns" id="def-btn">
                          <button
                            type="button"
                            className={`btn btn-cart ${
                              productOption?.availableStock === 0 &&
                              "bg-danger border border-danger"
                            }`}
                            onClick={() =>
                              this.addProductToCart(productOption._id, quantity)
                            }
                            data-bs-toggle={duration ? "offcanvas" : ""}
                            data-bs-target="#offcanvasRight"
                            disabled={productOption?.availableStock === 0}
                          >
                            {productOption.availableStock === 0
                              ? "Out of Stock"
                              : "Add to Cart"}{" "}
                            {loader}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Shop;
