import React, { PureComponent } from "react";
import axios from "axios";
import { server, RECAPTCHA_KEY } from "../env";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { notify } from "../utils/helper";
import Recaptcha from "react-google-invisible-recaptcha";
const Cookies = require("js-cookie");

class Reset extends PureComponent {
  state = {
    email: window.location.href.split("?email=")[1],
    loader: false,
    reCaptcha: null,
  };

  componentDidMount = () => {
    if (!this.state.email) window.location.href = "/forgot";
    var password = document.getElementById("password");
    var label = document.getElementById("label");
    var check = document.getElementById("check");

    check.onclick = function () {
      if (check.checked) {
        password.setAttribute("type", "text");
        label.innerHTML = "HIDE";
      } else {
        password.setAttribute("type", "password");
        label.innerHTML = "SHOW";
      }
    };
  };

  reset = async (e) => {
    e.preventDefault();
    await this.recaptcha.execute();

    const form = e.target;
    if (form.password.value !== form.password2.value) {
      notify("error", "Passwords do not match");
      return;
    }

    this.setState({ loader: true });
    const data = new FormData(form);
    data.append("email", this.state.email);
    data.append("g-recaptcha-response", await this.recaptcha.getResponse());

    await axios
      .post(server + "/v1/user/reset-password", data)
      .then((rsp) => {
        Cookies.set("token", rsp.data.payload.token);
        notify("success", rsp.data.message);
        window.location.href = "/";
      })
      .catch((err) => {
        if (err.response) {
          notify("error", err.response?.data?.message);
        }
      });
    this.setState({ loader: false });
  };

  onResolved = async () => {
    this.setState({ reCaptcha: this.recaptcha.getResponse() });
  };

  render() {
    const loader = this.state.loader;

    return (
      <div className="main-wrap-login" style={{ backgroundColor: "#FAFAFA" }}>
        <section className="signup-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6">
                <div className="signup-form-wrap">
                  <form onSubmit={this.reset}>
                    <div className="d-flex">
                      <h1>Reset Password</h1>
                    </div>
                    <div className="form-group">
                      <label for="">OTP</label>
                      <input
                        id="otp"
                        type="number"
                        name="otp"
                        className="form-control"
                        placeholder="Your OTP"
                        required
                        autoFocus
                      />
                    </div>
                    <div className="form-group">
                      <label for="">New Password</label>
                      <input
                        id="password"
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Set a new password"
                        required
                      />
                      <input id="check" className="check" type="checkbox" />
                      <label id="label" for="check">
                        SHOW
                      </label>
                    </div>
                    <div className="form-group">
                      <label for="">Confirm Password</label>
                      <input
                        id="password2"
                        type="password"
                        name="password2"
                        className="form-control"
                        placeholder="Confirm new password"
                        required
                      />
                    </div>
                    <div className="captcha-part"></div>
                    <div className="form-bttn">
                      <button type="submit" className="btn btn_submit">
                        Reset Password {loader && <Loader />}
                      </button>
                    </div>
                    <div className="form-group">
                      <p>
                        Facing any issue ?{" "}
                        <Link to="/signup" style={{ color: "#2FA4FF" }}>
                          Contact Support
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Recaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={RECAPTCHA_KEY}
          onResolved={this.onResolved}
        />
      </div>
    );
  }
}

export default Reset;
