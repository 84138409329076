import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import Requests from "../utils/requests";
const requests = new Requests();

class FAQ extends PureComponent {
  state = {
    faqs: [],
    isLoaded: false,
  };

  componentDidMount = async () => {
    await this.readFaqs();

    let cards = [...document.querySelectorAll(".accordion-item")];
    cards.forEach((card) => {
      card.addEventListener("click", function () {
        cards.forEach((c) => c.classList.remove("border-custom"));
        this.classList.add("border-custom");
      });
    });
  };

  readFaqs = async () => {
    this.setState({ faqs: await requests.getFaqs() });
    this.setState({ isLoaded: true });
  };

  render() {
    const faqs = this.state.faqs;

    return (
      <Fragment>
        <section className="faq-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="common-heading-txt">
                  <h6>FAQ</h6>
                  <h2>
                    Frequently Asked <span>Questions</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="faq-txt-area">
                  <div className="accordion" id="accordionExample">
                    {faqs.map((faq, i) => (
                      <div
                        className="accordion-item"
                        key={faq.id}
                        onClick={(e) =>
                          e.classList && e.classList.add("border-custom")
                        }
                      >
                        <div className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + i}
                            aria-expanded="true"
                            aria-controls={"collapse" + i}
                          >
                            <span>{faq.question}</span>
                          </button>
                        </div>
                        <div
                          id={"collapse" + i}
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>{faq.answer}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="more-bttn">
                  <span>Have more Questions ?</span>{" "}
                  <Link to="/tickets">
                    <i className="fas fa-circle"></i> Contact Us{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="get-start-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="get-start-txt">
                  <h2>Shop with Limitless </h2>

                  <Link
                    to="/shop"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Explore Store <i className="fas fa-arrow-right"></i>
                  </Link>

                  <p>
                    Instant Auto Replacement <i className="fas fa-circle"></i>{" "}
                    24/7 Customer Support
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default FAQ;
