import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import { server, config } from "../env";
import axios from "axios";
import Requests from "../utils/requests";
import { notify } from "../utils/helper";

const request = new Requests();

class Balance extends PureComponent {
  state = {
    balance: "",
    profile: {
      balance: 0.0,
    },
    paymentGateways: [],

    loader: false,
    paymentMethod: "coinbase",

    balanceSheet: [],
  };

  componentDidMount = async () => {
    this.setState({
      profile: await request.getProfile(),
      paymentGateways: await request.getPaymentGateways(),
      balanceSheet: await request.getBalanceSheet(),
    });
  };

  buyBalance = async () => {
    const { paymentMethod, balance } = this.state;
    if (balance <= 0) {
      notify("error", "Please enter a valid amount");
      return;
    }

    this.setState({ loader: true });
    const params = {
      paymentMethod,
      balance,
    };
    await axios
      .post(server + "/v1/balance/create", params, config)
      .then((rsp) => {
        const payload = rsp.data.payload;
        if (
          paymentMethod === "coinbase" ||
          paymentMethod === "stripe" ||
          paymentMethod === "shoppy"
        ) {
          window.location.href = payload.paymentUrl;
        }
      })
      .catch((err) => {
        notify("error", err.response?.data?.message);
      });
    this.setState({ loader: false });
  };

  render() {
    const { profile, paymentGateways, balanceSheet } = this.state;
    const { loader, paymentMethod } = this.state;

    return (
      <Fragment>
        <section className="dashboard-main-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-10">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="dahboard-home-head">
                          <h5>Account Balance</h5>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="text-end dash-home-heading">
                          <p>
                            Facing any issue ?{" "}
                            <Link to="/tickets">Contact Support</Link>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="balance-main-wrap">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="balance-display-wrap">
                            <div className="media">
                              <img
                                src="/assets/images/pocket-icon-2.svg"
                                alt="Pocket Icon"
                                className="img-fluid"
                              />
                              <div className="media-body">
                                <h6>Your Balance</h6>
                                <h3>${profile.balance?.toFixed(2)}</h3>
                              </div>
                            </div>
                            <div className="balance-btttn">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#addBalance"
                              >
                                Add Balance{" "}
                                <i className="fas fa-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                          <div className="add-balance-reason-wrap">
                            <h5>Why Add Balance to your Account?</h5>
                            <h6>
                              There are several reasons to definitely add
                              balance to your Account if you are a frequent
                              purchaser.
                            </h6>

                            <ul>
                              <li>
                                <i className="fas fa-circle"></i>Use your
                                balance on site-wide puchases without the hassle
                                of going through wallets and confirmations.
                              </li>
                              <li>
                                <i className="fas fa-circle"></i>Exclusive
                                Discount Codes for Users with sufficient account
                                balance.
                              </li>
                              <li>
                                <i className="fas fa-circle"></i>Combine your
                                leftover balance for your next purchase.
                              </li>
                              <li>
                                <i className="fas fa-circle"></i>It’s absolutely
                                free of cost, no transaction fees.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="balance-history-wrap">
                            <div className="balance-history-head">
                              <h6>Balance History</h6>
                            </div>

                            {balanceSheet.map((item, index) => (
                              <div
                                className="balance-history-box mb-0"
                                key={item._id}
                              >
                                <div>
                                  {new Date(item.createdAt).toDateString()}
                                </div>
                                <div
                                  className={
                                    item.type === "out"
                                      ? "text-dark"
                                      : "actv_clr"
                                  }
                                >
                                  {item.type === "out" ? "-" : "+"} $
                                  {item.amount?.toFixed(2)}
                                </div>
                                <div>
                                  {item.type === "out" ? (
                                    <span className="check_succs check_close">
                                      Debit
                                    </span>
                                  ) : (
                                    <span className="check_succs">Credit</span>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Add Balance */}
        <Modal id="addBalance" title="Add Balance">
          <div className="modal-body">
            <form>
              <div className="form-group d-flex justify-content-center align-items-center w-100 mb-4">
                <h6 style={{ fontSize: "25px" }}>Amount (In USD):</h6>
                <input
                  type="number"
                  name="balance"
                  id="balance"
                  required
                  min={1}
                  step={0.01}
                  className="px-3"
                  defaultValue={0}
                  style={{
                    marginLeft: "20px",
                    borderRadius: "15px",
                    background: "rgba(0, 195, 68, 0.1)",
                    border: "2px solid #00C344",
                    height: "57px",
                    fontSize: "25px",
                    width: "50%",
                  }}
                  onChange={(e) => {
                    this.setState({ balance: parseFloat(e.target.value) });
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Payment Methods:</label>
                <div className="payment-method-wrap mt-2 mb-3">
                  {paymentGateways.filter(
                    (gateway) => gateway.name === "coinbase"
                  ).length > 0 && (
                    <div
                      className={
                        paymentMethod === "coinbase"
                          ? "payment-method-box active  w-75"
                          : "payment-method-box  w-75"
                      }
                      onClick={() =>
                        this.setState({ paymentMethod: "coinbase" })
                      }
                    >
                      <img
                        src="/assets/images/bitcoin.svg"
                        alt="Bitcoin"
                        className="img-fluid"
                      />
                      <p>Crypto</p>
                    </div>
                  )}
                  {paymentGateways.filter(
                    (gateway) => gateway.name === "shoppy"
                  ).length > 0 && (
                    <div
                      className={
                        paymentMethod === "shoppy"
                          ? "payment-method-box active w-75"
                          : "payment-method-box w-75"
                      }
                      onClick={() => this.setState({ paymentMethod: "shoppy" })}
                    >
                      <img
                        src="/assets/images/pypl.svg"
                        alt="Bitcoin"
                        className="img-fluid"
                      />
                      <p>Paypal</p>
                    </div>
                  )}
                  {paymentGateways.filter(
                    (gateway) => gateway.name === "stripe"
                  ).length > 0 && (
                    <div
                      className={
                        paymentMethod === "stripe"
                          ? "payment-method-box active w-100"
                          : "payment-method-box w-100"
                      }
                      onClick={() => this.setState({ paymentMethod: "stripe" })}
                    >
                      <img
                        src="/assets/images/blnce-icon.svg"
                        alt="Bitcoin"
                        className="img-fluid"
                      />
                      <p>Credit/Debit Card</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div
                  className="balance-btttn"
                  style={{
                    width: "100%",
                  }}
                >
                  <Link
                    to="#"
                    style={{
                      display: "inline-block",
                      width: "100%",
                    }}
                    onClick={this.buyBalance}
                  >
                    Add Balance {loader && <Loader />}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default Balance;
