import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import Requests from "../utils/requests";
const requests = new Requests();

class Home extends PureComponent {
  state = {
    faqs: [],
    announcement: "",
  };

  componentDidMount = async () => {
    let cards = [...document.querySelectorAll(".accordion-item")];
    cards.forEach((card) => {
      card.addEventListener("click", function () {
        cards.forEach((c) => c.classList.remove("border-custom"));
        this.classList.add("border-custom");
      });
    });
    this.setState({
      faqs: await requests.getFaqs(),
      announcement: await requests.getAnnouncement(),
    });
  };

  render() {
    const { faqs, announcement } = this.state;

    return (
      <Fragment>
        {/* Hero Section */}
        <section className="hero-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="hero-txt-wrap">
                  <h6>
                    <span>NEW</span> {announcement}
                  </h6>
                  <h5>#1 PREMIUM AUTO REPLACEMENT SHOP </h5>
                  <h1>LIMITLESS.STORE</h1>
                  <p>
                    We Strive to offer only the best, longest lasting accounts
                    on the market! <br /> With over 5 years of great service,
                    our rep and feedback speaks for itself. <br /> You won't
                    regret shopping with us!
                  </p>
                  <div className="hero_btn">
                    <Link
                      to="/shop"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Explore Store <i className="fas fa-arrow-right"></i>
                    </Link>
                    <a
                      href="#features"
                      style={{
                        fontFamily: "Satoshi-Bold",
                      }}
                    >
                      Learn More
                    </a>
                  </div>

                  <div className="hero-bottom-wrap">
                    <ul>
                      <li>
                        <h4>35,000+</h4>
                      </li>
                      <li>
                        <span>ORDERS PLACED</span>
                      </li>
                      <li>
                        <span>|</span>
                      </li>
                      <li>
                        <h4>1,500+</h4>
                      </li>
                      <li>
                        <span>VOUCHES</span>
                      </li>
                      <li>
                        <span>|</span>
                      </li>
                      <li>
                        <h4>300+</h4>
                      </li>
                      <li>
                        <span>PRODUCTS</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* choice section */}
        <section className="why-choice-area" id="features">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="common-heading-txt">
                  <h6>FEATURES</h6>
                  <h2>
                    Why <span>Choose</span> Us?
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6">
                <div className="why-choice-box">
                  <div className="media">
                    <img
                      src="/assets/images/choice-1.svg"
                      alt="Choice"
                      className="img-fluid"
                    />
                    <div className="media-body">
                      <h6>Lightning Fast AutoReplacement</h6>

                      <p>
                        No more waiting times. Need a <br /> replacement? Just
                        click a button!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="why-choice-box">
                  <div className="media">
                    <img
                      src="/assets/images/choice-2.svg"
                      alt="Choice"
                      className="img-fluid"
                    />
                    <div className="media-body">
                      <h6>Highly Secure Transactions</h6>

                      <p>
                        We ensure full security and anonymity of all placed
                        orders
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="why-choice-box">
                  <div className="media">
                    <img
                      src="/assets/images/choice-3.svg"
                      alt="Choice"
                      className="img-fluid"
                    />
                    <div className="media-body">
                      <h6>Best Prices on the Accounts</h6>

                      <p>
                        Industry leading quality, unmatched by no other, only #1
                        Premium Accounts
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="why-choice-box">
                  <div className="media">
                    <img
                      src="/assets/images/choice-4.svg"
                      alt="Choice"
                      className="img-fluid"
                    />
                    <div className="media-body">
                      <h6>Industry Leading Support</h6>

                      <p>
                        Renowned for our top quality support, we will never let
                        you down
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="more-bttn">
                  <span>So what are you waiting for ?</span>{" "}
                  <Link
                    to="/shop"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Explore Store <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* payment options */}
        <section className="payment-option-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="common-heading-txt">
                  <h6>PAYMENTS</h6>
                  <h2>
                    We Accept <span>Payments</span> from
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="payment-option-box">
                  <h6>
                    <img
                      src="./assets/images/bitcoin.svg"
                      alt="Bitco"
                      className="img-fluid"
                    />{" "}
                    Bitcoin
                  </h6>
                  <span>BTC</span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="payment-option-box eth">
                  <h6>
                    <img
                      src="./assets/images/eth.svg"
                      alt="eth"
                      className="img-fluid"
                    />{" "}
                    Ethereum
                  </h6>
                  <span>ETH</span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="payment-option-box lite">
                  <h6>
                    <img
                      src="./assets/images/ltc.svg"
                      alt="ltc"
                      className="img-fluid"
                    />{" "}
                    Litecoin
                  </h6>
                  <span>LTC</span>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="payment-option-box pypl">
                  <h6>
                    <img
                      src="./assets/images/pypl.svg"
                      alt="pypl"
                      className="img-fluid"
                    />{" "}
                    PayPal
                  </h6>
                  {/* <span> F&amp;F</span> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* faq */}
        <section className="faq-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="common-heading-txt">
                  <h6>FAQ</h6>
                  <h2>
                    Frequently Asked <span>Questions</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="faq-txt-area">
                  <div className="accordion" id="accordionExample">
                    {faqs.map((faq, i) => (
                      <div
                        className="accordion-item"
                        key={faq.id}
                        onClick={(e) =>
                          e.classList && e.classList.add("border-custom")
                        }
                      >
                        <div className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + i}
                            aria-expanded="true"
                            aria-controls={"collapse" + i}
                          >
                            <span>{faq.question}</span>
                          </button>
                        </div>
                        <div
                          id={"collapse" + i}
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>{faq.answer}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="more-bttn">
                  <span>Have more Questions ?</span>{" "}
                  <Link to="/tickets">
                    <i className="fas fa-circle"></i> Contact Us{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* get start */}
        <section className="get-start-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="get-start-txt">
                  <h2>Shop with Limitless </h2>

                  <Link
                    to="/shop"
                    className="m-0"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Explore Store <i className="fas fa-arrow-right"></i>
                  </Link>

                  <p>
                    Instant Auto Replacement <i className="fas fa-circle"></i>{" "}
                    24/7 Customer Support
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Home;
