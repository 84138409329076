import React, { PureComponent } from "react";
import Requests from "../utils/requests";
import PageLoader from "../components/PageLoader";
import { Link } from "react-router-dom";
const requests = new Requests();

const renderStars = (rating) => {
  let stars = [];
  for (let i = 0; i < rating; i++)
    stars.push(
      <li>
        <i className="fas fa-star"></i>
      </li>
    );

  return stars;
};

class Feedback extends PureComponent {
  state = {
    feedbacks: [],
    products: [],
    pager: {
      page: 1,
    },
    isLoading: true,
  };

  componentDidMount = async () => {
    this.setState({ products: await requests.getProducts() });
    this.readFeedbacks();
  };

  readFeedbacks = async (page = this.state.pager.page, search = "") => {
    this.setState({ isLoading: true });
    const { feedbacks, pager } = await requests.getFeedbacks(page, search);
    this.setState({
      feedbacks,
      pager,
      isLoading: false,
    });
  };

  onProductSelect = (e) => {
    const value = e.target.value;
    if (!value) {
      this.readFeedbacks(1);
    } else {
      this.readFeedbacks(1, value);
    }
  };

  render() {
    const { feedbacks, products, pager, isLoading } = this.state;

    return (
      <section className="find-order-success-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="find-order-empty find-order-success">
                <h6>
                  <i className="fas fa-star"></i> Feedback{" "}
                  <i className="fas fa-star"></i>
                </h6>
                <h1>
                  What <span>Customers</span> Say
                </h1>
                <p>
                  At Limitless, we work endlessly to satisfy all our customers!
                  We listen, we care, and no issue is left unnoticed.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 text-center mx-auto">
              <select
                name="product"
                id=""
                className="form-control d-inline"
                onChange={this.onProductSelect}
                style={{
                  maxWidth: "200px",
                }}
              >
                <option value="">Filter Product</option>
                {products.map((product) => (
                  <option value={product._id}>{product.title}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row my-4">
            {feedbacks.map((feedback, index) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="feeback-box-wrap">
                  <img
                    src="/assets/images/quote.svg"
                    alt="Quote"
                    className="img-fluid"
                  />
                  <ul>{renderStars(feedback.stars)}</ul>
                  <p>{feedback.message}</p>
                  <br />
                  {feedback.product?.title && (
                    <p className="text-start">
                      - <span>{feedback.product?.title}</span>
                    </p>
                  )}
                </div>
              </div>
            ))}
            {isLoading && <PageLoader />}
          </div>

          <nav className="mt-3">
            <ul className="pagination justify-content-center">
              {pager.previous && (
                <li
                  className="page-item bg-light rounded"
                  onClick={() => this.readFeedbacks(pager.previous, null)}
                >
                  <Link
                    to="#"
                    className="page-link"
                    style={{
                      height: "38px",
                      paddingTop: "10px",
                    }}
                  >
                    <i className="bi bi-chevron-double-left"></i>
                  </Link>
                </li>
              )}

              {pager.previous && (
                <li
                  className="page-item bg-light rounded"
                  onClick={() => this.readFeedbacks(pager.previous, null)}
                >
                  <Link to="#" className="page-link">
                    {pager.page - 1}
                  </Link>
                </li>
              )}

              <li className="page-item bg-primary rounded">
                <Link to="#" className="page-link" href="#">
                  {pager.page}
                </Link>
              </li>

              {pager.next && (
                <li
                  className="page-item bg-light rounded"
                  onClick={() => this.readFeedbacks(pager.next, null)}
                >
                  <Link to="#" className="page-link">
                    {pager.page + 1}
                  </Link>
                </li>
              )}

              {pager.next && (
                <li
                  className="page-item bg-light rounded"
                  onClick={() => this.readFeedbacks(pager.next, null)}
                >
                  <Link
                    to="#"
                    className="page-link"
                    href="#"
                    style={{
                      height: "38px",
                      paddingTop: "10px",
                    }}
                  >
                    <i className="bi bi-chevron-double-right"></i>
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </section>
    );
  }
}

export default Feedback;
