import React, { PureComponent } from "react";
import { notify } from "../utils/helper";
import Requests from "../utils/requests";
import Loader from "../components/Loader";
import PageLoader from "../components/PageLoader";
const requests = new Requests();

class Settings extends PureComponent {
  state = {
    isLoaded: "",

    email: "",
    username: "",
    usernameLoader: "",
    emailLoader: "",
    passwordLoader: "",
  };

  componentDidMount = async () => {
    const profile = await requests.getProfile();
    this.setState({
      email: profile.email,
      username: profile.username,
      isLoaded: true,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  updateUsername = async (e) => {
    e.preventDefault();
    this.setState({ usernameLoader: <Loader /> });
    const { error, message } = await requests.updateUsername(
      e.target.username.value
    );
    notify(error ? "error" : "success", message);
    this.setState({ usernameLoader: "" });
  };

  updateEmail = async (e) => {
    e.preventDefault();
    this.setState({ emailLoader: <Loader /> });
    const { error, message } = await requests.updateEmail(e.target.email.value);
    notify(error ? "error" : "success", message);
    this.setState({ emailLoader: "" });
  };

  updatePassword = async (e) => {
    e.preventDefault();
    if (e.target.password.value !== e.target.cnf.value) {
      notify("error", "Passwords do not match");
      return;
    }

    this.setState({ passwordLoader: <Loader /> });
    const { error, message } = await requests.updatePassword(
      e.target.password.value
    );
    notify(error ? "error" : "success", message);
    this.setState({ passwordLoader: "" });
  };

  render() {
    const {
      isLoaded,
      email,
      username,
      usernameLoader,
      emailLoader,
      passwordLoader,
    } = this.state;

    return (
      <section className="find-order-area mb-18">
        <div className="container">
          <div className="text-center mb-5">
            <div class="common-heading-txt">
              <h6>Account</h6>
              <h2>
                Account <span>Settings</span>
              </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            {isLoaded && (
              <div className="col-lg-7">
                <div className="row">
                  {/* Username */}
                  <div className="col-md-6">
                    <div className="balance-history-wrap pb-1 shadow-sm">
                      <div className="balance-history-head">
                        <h6>Change Username</h6>
                      </div>
                      <div className="card-body">
                        <form onSubmit={this.updateUsername}>
                          <div className="form-group">
                            <label htmlFor="">Username</label>
                            <input
                              type="text"
                              name="username"
                              id="username"
                              className="form-control br-15px shadow-sm"
                              value={username}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                          <div className="form-group mt-3 text-end">
                            <button
                              type="submit"
                              className="btn btn-primary br-15px px-3"
                              style={{
                                backgroundColor: "#038aed",
                                border: "unset",
                              }}
                            >
                              Change {usernameLoader}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  {/* email */}
                  <div className="col-md-6">
                    <div className="balance-history-wrap pb-1 shadow-sm">
                      <div className="balance-history-head">
                        <h6>Change Email</h6>
                      </div>
                      <div className="card-body">
                        <form onSubmit={this.updateEmail}>
                          <div className="form-group">
                            <label htmlFor="">Email</label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="form-control br-15px shadow-sm"
                              value={email}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                          <div className="form-group mt-3 text-end">
                            <button
                              type="submit"
                              className="btn btn-primary br-15px px-3"
                              style={{
                                backgroundColor: "#038aed",
                                border: "unset",
                              }}
                            >
                              Change {emailLoader}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  {/* password */}
                  <div className="col-md-12 mt-5">
                    <div className="balance-history-wrap pb-1 shadow-sm">
                      <div className="balance-history-head">
                        <h6>Change Password</h6>
                      </div>
                      <div className="card-body">
                        <form onSubmit={this.updatePassword}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="">Password</label>
                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  className="form-control br-15px shadow-sm"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="">Confirm Password</label>
                                <input
                                  type="text"
                                  name="cnf"
                                  id="cnf"
                                  className="form-control br-15px shadow-sm"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group mt-3 text-end">
                            <button
                              type="submit"
                              className="btn btn-primary br-15px px-3"
                              style={{
                                backgroundColor: "#038aed",
                                border: "unset",
                              }}
                            >
                              Change {passwordLoader}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!isLoaded && <PageLoader />}
          </div>
        </div>
      </section>
    );
  }
}

export default Settings;
