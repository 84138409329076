import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import Cart from "./Cart";
import Requests from "../utils/requests";

const Cookies = require("js-cookie");
const requests = new Requests();
const jq = window.jQuery;

export default class Header extends PureComponent {
  state = {
    tab: window.location.href.split("/")[3],
    profile: {
      balance: 0.0,
    },
    cartItems: [],
    cartPrice: 0.0,
  };

  componentDidMount = async () => {
    setInterval(() => {
      this.setState({
        tab: window.location.href.split("/")[3],
      });
    }, 1000);

    this.setState({
      profile: await requests.getProfile(),
    });
    this.refreshCart();
    jq(".nav-link").on("click", function () {
      console.log("clicked");
      jq("#navbarText").removeClass("show");
    });
  };

  logout = () => {
    Cookies.remove("token");
    localStorage.removeItem("email");
    window.location.href = "/login";
  };

  refreshCart = async () => {
    const { cartItems, cartPrice } = await requests.getCartItems();
    this.setState({
      cartItems,
      cartPrice,
    });
  };

  render() {
    const { tab, profile, cartItems, cartPrice } = this.state;

    return (
      <Fragment>
        <header className="header_section pt-2" id="header">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light py-0">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img
                    src="/assets/images/logo.svg"
                    alt="Logo"
                    className="img-fluid"
                  />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarText"
                  aria-controls="navbarText"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fa-solid fa-bars-staggered"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                  <ul className="navbar-nav m-auto">
                    {this.props.loggedIn && (
                      <li className="nav-item">
                        <Link
                          className={
                            tab === "/dashboard" || tab === "dashboard"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/dashboard"
                          onClick={() => jq("#navbarText").removeClass("show")}
                        >
                          Dashboard
                        </Link>
                      </li>
                    )}
                    <li className="nav-item">
                      <Link
                        className={
                          tab === "/shop" || tab === "shop"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/shop"
                      >
                        Store
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          tab === "/feedback" || tab === "feedback"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/feedback"
                      >
                        Feedback
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          tab === "/redeem-key" || tab === "redeem-key"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/redeem-key"
                      >
                        Redeem Key
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          tab === "/faqs" || tab === "faqs"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/faqs"
                      >
                        FAQ
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className={
                          tab === "/find-order" || tab === "find-order"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/find-order"
                      >
                        Find Order
                      </Link>
                    </li>

                    {this.props.loggedIn && (
                      <li className="nav-item">
                        <Link
                          className={
                            tab === "/tickets" || tab === "tickets"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/tickets"
                          onClick={() => jq("#navbarText").removeClass("show")}
                        >
                          Tickets
                        </Link>
                      </li>
                    )}
                  </ul>

                  <form
                    className={
                      this.props.loggedIn ? "dashboard-head-bttn" : "d-flex"
                    }
                  >
                    {this.props.loggedIn ? (
                      <Link
                        to="/balance"
                        onClick={() => jq("#navbarText").removeClass("show")}
                      >
                        <img
                          src="/assets/images/pockt-2.svg"
                          alt="a"
                          class="img-fluid"
                        />{" "}
                        ${profile.balance?.toFixed(2)}
                      </Link>
                    ) : (
                      <Fragment>
                        <Link to="/login">Sign In</Link>
                        <Link
                          style={{
                            fontFamily: "Satoshi-Bold",
                          }}
                          to="/signup"
                        >
                          Create Account{" "}
                        </Link>
                      </Fragment>
                    )}

                    <Link
                      to="#"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      onClick={() => jq("#navbarText").removeClass("show")}
                    >
                      <i className="fas fa-shopping-cart"></i>{" "}
                      <span>{cartItems?.productOptions?.length || 0}</span>
                    </Link>

                    {this.props.loggedIn && (
                      <form className="dashboard-head-bttn">
                        <Link
                          to="/settings"
                          onClick={() => jq("#navbarText").removeClass("show")}
                          style={{
                            height: "50px",
                          }}
                        >
                          <i className="fas fa-cog"></i>
                        </Link>
                      </form>
                    )}

                    {this.props.loggedIn && (
                      <Link to="#" onClick={this.logout}>
                        <img
                          src="/assets/images/cart-icon.svg"
                          alt="a"
                          class="img-fluid"
                        />
                      </Link>
                    )}
                  </form>
                </div>
              </div>
            </nav>
          </div>
        </header>

        <Cart
          cartItems={cartItems}
          cartPrice={cartPrice}
          refreshCart={this.refreshCart}
        />
      </Fragment>
    );
  }
}
