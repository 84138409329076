import React from "react";

export default function modal(props) {
  return (
    <div className="modal fade" id={props.id} style={props.style}>
      <div className={props.className + " modal-dialog modal-dialog-centered"}>
        <div
          className="modal-content shadow-sm"
          style={{
            borderRadius: "20px",
          }}
        >
          <div className="modal-header p-3 border-0">
            <p className="pb-0 mb-0">{props.title}</p>
            <span
              type="button"
              className="close text-danger"
              data-bs-dismiss="modal"
              aria-label="Close"
              id={"close" + props.id}
            >
              <span aria-hidden="true">&times;</span>
            </span>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
}
