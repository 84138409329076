const { toast } = require("react-toastify");

// notification
export function notify(toast_type, msg) {
  toast(msg, {
    type: toast_type,
    theme: "colored",
    position: toast.POSITION.TOP_RIGHT,
  });
}

export function closeModal(id) {
  const closeButton = document.getElementById(id);
  if (closeButton) closeButton.click();
}

export function generateRadomString(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
