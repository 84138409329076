import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Requests from "../utils/requests";
// import "react-quill/dist/quill.snow.css";
const requests = new Requests();

class PrivacyPolicy extends PureComponent {
  state = {
    doc: {},
  };

  componentDidMount = async () => {
    const headerDom = document.getElementById("header");
    if (headerDom) headerDom.style.display = "none";
    const footerDom = document.getElementById("footer");
    if (footerDom) footerDom.style.display = "none";

    const docs = await requests.readDocs();

    this.setState({
      doc: docs.find((doc) => doc.name === "Privacy Policy"),
    });
  };

  render() {
    const doc = this.state.doc;

    return (
      <section className="tos-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="tos-header">
                <Link to="/">
                  <img
                    src="/assets/images/logo.svg"
                    alt="Logo"
                    className="img-fluid"
                  />
                </Link>
                <a href="/">
                  <i className="fas fa-arrow-left"></i> Go Back to Home Page
                </a>
              </div>
              <div className="tos-main-wrap">
                <h1>Privacy Policy</h1>

                <div
                  dangerouslySetInnerHTML={{
                    __html: doc?.description,
                  }}
                ></div>
              </div>
              <div className="tos-footer-wrap">
                <ul>
                  <li>
                    <Link to="/">&copy; Limitless.ac</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-service">Terms of Service</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PrivacyPolicy;
