import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import Requests from "../utils/requests";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import { notify } from "../utils/helper";
import { server, RECAPTCHA_KEY } from "../env";
import Recaptcha from "react-google-invisible-recaptcha";
const requests = new Requests();

class FindOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.copyRef = React.createRef();
  }

  state = {
    id: "",
    order: null,
    orderRequests: [],
    orderProduct: {},
    loader: "",
    requestLoader: "",

    rating: 5,
    feedbackLoader: "",
    feedbackProduct: {},

    orderId: "",
    productId: "",
    productOptionId: "",
    credIndex: null,
    reCaptcha: null,
    replacement: 0,
  };

  componentDidMount = () => {
    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");
    this.setState({ id });
    if (id) this.getOrder(id);
  };

  onResolved = async () => {
    this.setState({ reCaptcha: this.recaptcha.getResponse() });
  };

  getOrder = async (e) => {
    var id = this.state.id;
    if (typeof e === "string") id = e;
    else e.preventDefault();

    this.setState({ loader: <Loader /> });

    const order = await requests.getOrder(id);
    if (!order) notify("error", "Order Not Found!");
    else this.setState({ order });

    this.setState({ loader: "" });
  };

  copy = (cred, id) => {
    // remove last space from cred
    cred = cred.trim();
    navigator.clipboard.writeText(cred);
    const element = document.getElementById(id);
    element.innerText = "Copied";
    const backgroundImage = element.style.backgroundImage;
    element.style.backgroundImage = "none";
    element.style.paddingRight = "45px";
    setTimeout(() => {
      element.style.backgroundImage = backgroundImage;
      element.style.paddingRight = "unset";
      element.innerText = "";
    }, 3000);
  };

  readOrderRequests = async (orderId, productId, productOptionId) => {
    this.setState({
      orderRequests: await requests.getOrderRequests(
        orderId,
        productId,
        productOptionId
      ),
    });
  };

  requestReplacement = async (e) => {
    e.preventDefault();
    if (this.state.replacement >= 1) window.location.reload();
    this.setState({
      replacement: 1,
    });
    await this.recaptcha.execute();
    const recaptcha = await this.recaptcha.getResponse();
    const { orderId, productId, productOptionId, credIndex } = this.state;
    this.setState({ requestLoader: <Loader /> });
    var { error, message } = await requests.requestReplacement(
      orderId,
      productId,
      productOptionId,
      e.target.message.value,
      credIndex,
      recaptcha
    );
    if (error) {
      notify("error", message);
    } else {
      notify("success", message);
      this.getOrder(orderId);
    }

    this.getOrder();
    this.setState({ requestLoader: "" });
    this.hideModal("request");
  };

  sendFeedback = async (e) => {
    e.preventDefault();
    this.setState({ feedbackLoader: <Loader /> });

    var { error, msg } = await requests.sendFeedback(
      this.state.feedbackProduct.orderId,
      this.state.feedbackProduct.productId,
      this.state.feedbackProduct.productOptionId,
      this.state.rating,
      e.target.message.value
    );

    if (error) notify("error", msg);
    else notify("success", msg);

    this.setState({ feedbackLoader: "" });
  };

  hideModal = (id) => {
    const dom = document.getElementById("close" + id);
    if (dom) dom.click();
  };

  render() {
    const { loader, order } = this.state;
    const { orderRequests, orderProduct, requestLoader } = this.state;
    const { rating, feedbackLoader } = this.state;

    return (
      <section className="find-order-area mb-18">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="find-order-empty">
                <h1>
                  Find <span>Order</span>
                </h1>
                <p>Enter your Order ID to know more about your order</p>

                <div className="ordr-find-search-wrap">
                  <form onSubmit={this.getOrder}>
                    <input
                      type="text"
                      placeholder="Enter your Order ID"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          id: e.target.value,
                        })
                      }
                    />
                    <button type="submit" className="btn btn-search">
                      Search {loader}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {order && (
            <div className="row">
              {order.products.map((product, index) => (
                <div className="col-lg-12">
                  <div className="find-result-head">
                    <h6>
                      Order ID : <span>{order._id} </span>
                    </h6>
                  </div>
                  <div className="find-order-result-wrap">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="find-result-img-wrap">
                          <img
                            src={server + product.picture}
                            alt="Netflix"
                            className="img-fluid"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="find-result-form">
                              <h6>{product.title}</h6>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="find-result-form">
                              <h6
                                style={{ color: "rgba(36, 36, 36, 0.51)" }}
                                className="text-end"
                              >
                                {product.subtitle}
                              </h6>
                            </div>
                          </div>

                          <div className="col-lg-1"></div>
                          <div
                            className={
                              !product.isReplacement
                                ? "col-lg-5 mb-3"
                                : "col-lg-4 mb-3"
                            }
                          >
                            <label htmlFor="">Username</label>
                          </div>
                          <div
                            className={
                              !product.isReplacement
                                ? "col-lg-6 mb-3"
                                : "col-lg-5 mb-3"
                            }
                          >
                            <label htmlFor="">Password</label>
                          </div>
                          {product.isReplacement && (
                            <div className="col-lg-2"></div>
                          )}

                          {order.status !== "pending" &&
                            product.creds.map((cred, index) =>
                              cred.split(":").map((c, i) => (
                                <Fragment>
                                  {i === 0 && (
                                    <div className="col-lg-1 mb-3 mt-2">
                                      #{index + 1}
                                    </div>
                                  )}
                                  <div
                                    className={
                                      !product.isReplacement
                                        ? i === 0
                                          ? "col-lg-5 mb-3"
                                          : "col-lg-6 mb-3"
                                        : i === 0
                                        ? "col-lg-4 mb-3"
                                        : "col-lg-5 mb-3"
                                    }
                                    key={i}
                                  >
                                    <div className="find-result-form">
                                      <div className="input-wrap">
                                        <input
                                          type="text"
                                          value={c}
                                          className="form-control"
                                        />
                                        <span
                                          id={"copy" + index + i}
                                          style={{
                                            backgroundImage: `url("/assets/images/copy-icon.svg")`,
                                            backgroundRepeat: "no-repeat",
                                            height: "25px",
                                            width: "25px",
                                          }}
                                          onClick={(e) => {
                                            this.copy(c, "copy" + index + i);
                                          }}
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                  {cred.split(":").length === i + 1 &&
                                    product.isReplacement && (
                                      <div className="col-lg-2 mb-3">
                                        <button
                                          className={
                                            !product.isReplacement
                                              ? "d-none"
                                              : "btn btn-danger w-100"
                                          }
                                          style={{
                                            borderRadius: "8.34146px",
                                            height: "45px",
                                            backgroundColor: "#fd2f22",
                                            border: "none",
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              orderId: order._id,
                                              productId: product.product,
                                              productOptionId:
                                                product.productOption,
                                              credIndex: index,
                                            })
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#request"
                                        >
                                          Replace
                                        </button>
                                      </div>
                                    )}
                                </Fragment>
                              ))
                            )}

                          {product.creds.length === 0 && (
                            <div className="col-12">
                              <div className="order-result-bttns">
                                Product Credentials:
                                <span className="badge bg-warning ms-2">
                                  N/A
                                </span>
                                <Link
                                  className="nav-link ms-0 ps-0"
                                  to={"/tickets?orderId=" + order._id}
                                >
                                  Contact Us
                                </Link>
                              </div>
                            </div>
                          )}

                          {order.status === "pending" && (
                            <div className="col-12">
                              <div className="order-result-bttns">
                                Order Status:
                                <span className="badge bg-warning ms-2">
                                  Pending
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="col-12">
                            <div className="order-result-bttns">
                              <p>
                                <span
                                  style={{ color: "rgba(36, 36, 36, 0.5)" }}
                                >
                                  About :
                                </span>{" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: product.description?.replaceAll(
                                      "\n",
                                      "<br/>"
                                    ),
                                  }}
                                ></span>
                              </p>
                            </div>
                          </div>
                          <div className="col-12 text-end">
                            <div className="order-result-bttn">
                              <Link
                                to="#"
                                onClick={() =>
                                  this.setState({
                                    orderProduct: product,
                                  })
                                }
                                data-bs-toggle="modal"
                                data-bs-target="#viewOrder"
                              >
                                <img
                                  src="./assets/images/info-btn.svg"
                                  alt="Info"
                                  className="img-fluid"
                                />
                              </Link>
                              <Link
                                to="#"
                                className={
                                  product.creds.length === 0 && "d-none"
                                }
                                onClick={() =>
                                  this.readOrderRequests(
                                    order._id,
                                    product.product,
                                    product.productOption
                                  )
                                }
                                data-bs-toggle="modal"
                                data-bs-target="#history"
                              >
                                <i className="fas fa-history"></i> View History
                              </Link>
                              <Link
                                to="#"
                                className={
                                  product.creds.length === 0 && "d-none"
                                }
                                onClick={() => {
                                  this.setState({
                                    feedbackProduct: {
                                      orderId: order._id,
                                      productId: product.product,
                                      productOptionId: product.productOption,
                                    },
                                  });
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#feedback"
                              >
                                <i className="fas fa-comment-dots"></i> Feedback
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* History Modal */}
        <Modal
          id="history"
          title="Product Replacement History"
          className="modal-lg"
        >
          <div className="modal-body">
            <div className="table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>STATUS</th>
                    <th>DATE</th>
                    <th>CREDENTIALS</th>
                  </tr>
                </thead>
                <tbody>
                  {orderRequests.map((request, index) => (
                    <tr>
                      <td>{request._id}</td>
                      <td>
                        {request.status === "pending" && (
                          <span className="badge bg-warning ms-2">PENDING</span>
                        )}
                        {request.status === "completed" && (
                          <span className="badge bg-success ms-2">
                            COMPLETED
                          </span>
                        )}
                        {request.status === "rejected" && (
                          <span className="badge bg-danger ms-2">REJECTED</span>
                        )}
                      </td>
                      <td>{new Date(request.createdAt).toDateString()}</td>
                      <td>
                        {request.status === "completed"
                          ? request.creds.join(", ")
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                  {orderRequests.length === 0 && (
                    <tr>
                      <td colSpan={4}>
                        <div className="text-center">
                          <h6>No history found</h6>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>

        {/* View Order */}
        <Modal id="viewOrder" title="View Order" className="modal-lg">
          {order && (
            <div className="modal-body">
              <table className="table">
                <tr className="border-0" style={{ lineHeight: 2 }}>
                  <th>Order ID: </th>
                  <td>{order._id}</td>
                </tr>
                <tr className="border-0" style={{ lineHeight: 2 }}>
                  <th>Product: </th>
                  <td>{orderProduct.title}</td>
                </tr>
                <tr className="border-0" style={{ lineHeight: 2 }}>
                  <th>Product Variant: </th>
                  <td>{orderProduct.subtitle}</td>
                </tr>
                <tr className="border-0" style={{ lineHeight: 2 }}>
                  <th>Price: </th>
                  <td>${orderProduct.price?.toFixed(2)}</td>
                </tr>
                <tr className="border-0" style={{ lineHeight: 2 }}>
                  <th>Quantity: </th>
                  <td>{orderProduct.quantity}</td>
                </tr>
                <tr className="border-0" style={{ lineHeight: 2 }}>
                  <th>Is Replacement Available: </th>
                  <td>{orderProduct.isReplacement ? "YES" : "NO"}</td>
                </tr>
                <tr className="border-0" style={{ lineHeight: 2 }}>
                  <th>Duration: </th>
                  <td>{orderProduct.duration} Days</td>
                </tr>
                {orderProduct.creds?.length !== 0 && (
                  <Fragment>
                    <tr className="border-0" style={{ lineHeight: 2 }}>
                      <th>Expiring: </th>
                      <td>{new Date(orderProduct.expiry).toDateString()}</td>
                    </tr>
                    <tr className="border-0" style={{ lineHeight: 2 }}>
                      <th>Credentials: </th>
                      <td>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="3"
                          className="form-control"
                          value={orderProduct.creds?.join(", ")}
                        ></textarea>
                      </td>
                    </tr>
                  </Fragment>
                )}
              </table>
            </div>
          )}
        </Modal>

        {/* Request Replacement */}
        <Modal id="request" title="Request Replacement">
          <div className="modal-body">
            <form onSubmit={this.requestReplacement}>
              <div className="form-group mb-3">
                <label htmlFor="">Message</label>
                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows="3"
                  className="form-control"
                  required
                  placeholder="Please explain in detail so we can help. Do not type 'Doesn't work'"
                ></textarea>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary w-100 text-center"
                  style={{
                    border: "2px solid #038aed",
                    backgroundColor: "#038aed",
                    borderRadius: "8.21779px",
                  }}
                >
                  <i className="fas fa-paper-plane"></i> Send Request{" "}
                  {requestLoader}
                </button>
              </div>
            </form>
          </div>
        </Modal>

        {/* feedback */}
        <Modal id="feedback" title="Product Feedback" className="modal-lg">
          <div className="modal-body">
            <form onSubmit={this.sendFeedback}>
              <div className="form-group mb-3">
                <label htmlFor="">Message</label>
                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows="3"
                  className="form-control"
                  required
                ></textarea>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="">Rating</label>
                <div>
                  <span
                    onClick={() => this.setState({ rating: 1 })}
                    style={{
                      color: rating >= 1 ? "#ffc107" : "#ccc",
                      fontSize: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fas fa-star"></i>
                  </span>

                  <span
                    onClick={() => this.setState({ rating: 2 })}
                    style={{
                      color: rating >= 2 ? "#ffc107" : "#ccc",
                      fontSize: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <i
                      className="fas fa-star"
                      onClick={() => this.setState({ rating: 2 })}
                    ></i>
                  </span>

                  <span
                    onClick={() => this.setState({ rating: 3 })}
                    style={{
                      color: rating >= 3 ? "#ffc107" : "#ccc",
                      fontSize: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fas fa-star"></i>
                  </span>

                  <span
                    onClick={() => this.setState({ rating: 4 })}
                    style={{
                      color: rating >= 4 ? "#ffc107" : "#ccc",
                      fontSize: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fas fa-star"></i>
                  </span>

                  <span
                    onClick={() => this.setState({ rating: 5 })}
                    style={{
                      color: rating >= 5 ? "#ffc107" : "#ccc",
                      fontSize: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fas fa-star"></i>
                  </span>
                </div>
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary w-100 text-center"
                  style={{
                    border: "2px solid #038aed",
                    backgroundColor: "#038aed",
                    borderRadius: "8.21779px",
                  }}
                >
                  <i className="fas fa-paper-plane"></i> Send {feedbackLoader}
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <Recaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={RECAPTCHA_KEY}
          onResolved={this.onResolved}
        />
      </section>
    );
  }
}

export default FindOrder;
