import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import Requests from "../utils/requests";
const requests = new Requests();

class Footer extends PureComponent {
  state = {
    links: [],
  };

  componentDidMount = async () => {
    this.setState({
      links: await requests.getSocialMediaLinks(),
    });
  };

  render() {
    const { links } = this.state;
    return (
      <footer className="footer_section" id="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-sm-6 col-12">
              <div className="ftr-logo">
                <Link to="/">
                  <img
                    src="/assets/images/logo-ftr.svg"
                    alt="Logo"
                    className="img-fluid"
                  />
                </Link>

                <p>There are no Limits in a Limitless universe.</p>

                <p>
                  Copyright &copy; 2022 <Link to="#">Limitless.store</Link>
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-6">
              <div className="ftr-links">
                <h6>STORE</h6>
                <ul>
                  <li>
                    <Link to="/shop">Shop</Link>
                  </li>
                  <li>
                    <Link to="/feedback">Feedback</Link>
                  </li>
                  <li>
                    <Link to="/redeem-key">Redeem Key</Link>
                  </li>
                  <li>
                    <Link to="/find-order">Find Order</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-6">
              <div className="ftr-links">
                <h6>ACCOUNT</h6>
                <ul>
                  {!this.props.loggedIn && (
                    <Fragment>
                      <li>
                        <Link to="/signup">Create Account</Link>
                      </li>
                      <li>
                        <Link to="/login">Sign In</Link>
                      </li>
                    </Fragment>
                  )}
                  <li>
                    <Link
                      to="#"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                    >
                      Your Cart
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 col-sm-6 col-12">
              <div className="ftr-links">
                <h6>LEGAL</h6>
                <ul>
                  <li>
                    <Link to="/terms-of-service">Terms of Service</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="ftr-social">
                <h6>SOCIAL MEDIA</h6>
                <ul>
                  <li>
                    <a
                      href={links.find((l) => l.name === "discord")?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-discord"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={links.find((l) => l.name === "youtube")?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={links.find((l) => l.name === "instagram")?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={links.find((l) => l.name === "telegram")?.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fas fa-paper-plane"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
